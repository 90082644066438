import axios from "./axios";

interface FetchSubjectsRequest {
  token: string;
}

interface FetchSubjectsResponse {
  data: {
    status: "success" | "error";
    subjects?: {
      subject_id: string;
      subject_name: string;
    }[];
    errorMessage?: string;
  };
}

export async function fetchSubjects(
  data: FetchSubjectsRequest
): Promise<FetchSubjectsResponse> {
  return axios.post("data/fetch-subjects.php", data);
}

interface FetchCategoriesRequest {
  token: string;
}

interface FetchCategoriesResponse {
  data: {
    status: "success" | "error";
    categories?: {
      category_id: string;
      category_name: string;
    }[];
    errorMessage?: string;
  };
}

export async function fetchCategories(
  data: FetchCategoriesRequest
): Promise<FetchCategoriesResponse> {
  return axios.post("data/fetch-categories.php", data);
}
