import { FC, useContext } from "react";

import ThemeContext from "../context/ThemeContext";

import { ThemeLightIcon, ThemeDarkIcon } from "./Icons";

const ThemeToggle: FC = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <span className="cursor-pointer" onClick={() => toggleTheme()}>
      {theme === "light" ? (
        <ThemeLightIcon className="fill-i-black group-hover:animate-pulse" />
      ) : (
        <ThemeDarkIcon className="fill-white group-hover:animate-pulse" />
      )}
    </span>
  );
};

export default ThemeToggle;
