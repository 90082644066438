import { FC } from "react";

import MainLayout from "../components/MainLayout";
import MainHeading from "../components/MainHeading";
import { EmptyIcon } from "../components/Icons";

interface BookstoreThanksPageProps {}

const BookstoreThanksPage: FC<BookstoreThanksPageProps> = () => {
  return (
    <MainLayout>
      <div className="mt-20 flex flex-col justify-center items-center text-center">
        <EmptyIcon className="mb-20 fill-d-green dark:fill-l-green" />

        <MainHeading plainText={"Betaling"} themeText={"Suksesvol!"} />

        <p className="mt-10 max-w-[550px] text-cente dark:text-white">
          Baie dankie vir die ondersteuning van Nukleus Onderwys. Jou aankoop
          was suksesvol en jou boeke is gekoppel/bestel. Indien jy enige
          probleme of navrae het kontak ons ​​asseblief.
        </p>
      </div>
    </MainLayout>
  );
};

export default BookstoreThanksPage;
