import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";

import { CompletedIcon } from "../components/Icons";
import { CloseIcon } from "../components/Icons";

interface AssessmentCompletedCardProps {
  assessmentCompleted: AssessmentCompleted;
}

interface Assessment {
  assessment_id: number;
  assessment_title: string;
  grade: number;
  module: number;
  subject_id: number;
  assessment_url: string;
  o_datetime: string;
  c_datetime: string;
  date_created: string;
  subject: string;
  completed: string;
}

interface AssessmentCompleted {
  assessment_completed_id: number;
  assessment_id: number;
  student_id: number;
  assessment_completed_url: string;
  assessment_completed_filename: string;
  assessment_marked_url: string;
  marked_by: number;
  resubmit_flag: string;
  date_created: string;
  assessment: Assessment;
}

const AssessmentCompletedCard: FC<AssessmentCompletedCardProps> = ({
  assessmentCompleted,
}) => {
  const navigate = useNavigate();

  const getDate = (timeString: string) => {
    const listOfMonths = [
      "Januarie",
      "Februarie",
      "Maart",
      "April",
      "Mei",
      "Junie",
      "Julie",
      "Augustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    const currentDate = new Date(timeString);

    return (
      currentDate.getDate() +
      " " +
      listOfMonths[currentDate.getMonth()] +
      " " +
      currentDate.getFullYear()
    );
  };

  const getTime = (timeString: string) => {
    const currentTime = new Date(timeString);

    return (
      (currentTime.getHours() < 10
        ? "0" + currentTime.getHours()
        : currentTime.getHours()) +
      ":" +
      (currentTime.getMinutes() < 10
        ? "0" + currentTime.getMinutes()
        : currentTime.getMinutes())
    );
  };

  const isComplete = (timeString: string) => {
    return new Date().valueOf() > new Date(timeString).valueOf();
  };

  const handleAssessmentClick = (assessment: Assessment) => {
    localStorage.setItem("active-assessment", JSON.stringify(assessment));
    navigate("/no/testing");
  };

  return (
    <div className="mb-8 mx-auto flex justify-between items-center">
      <div className="w-full lg:w-auto lg:flex-grow px-8 py-8 flex flex-col lg:flex-row justify-between items-center bg-white rounded-xl shadow-xl dark:bg-l-gray dark:text-white">
        <div className="mb-8 lg:mb-0 flex flex-col lg:flex-row justify-center items-center">
          {assessmentCompleted.resubmit_flag === "true" ? (
            <CloseIcon className="fill-red dark:fill-red hidden lg:inline" />
          ) : (
            <CompletedIcon
              className={
                assessmentCompleted.assessment_marked_url
                  ? "fill-d-green dark:fill-l-green hidden lg:inline"
                  : "fill-black dark:fill-white hidden lg:inline"
              }
            />
          )}

          <span className="w-[350px] lg:ml-14 text-2xl text-center lg:text-left">
            {assessmentCompleted.assessment.assessment_title}
          </span>

          <span>
            Ingedien: {getDate(assessmentCompleted.date_created)} -{" "}
            {getTime(assessmentCompleted.date_created)}
          </span>
        </div>

        {assessmentCompleted.assessment_marked_url ? (
          <span
            className="text-d-green cursor-pointer hover:underline dark:text-l-green"
            onClick={() => {
              saveAs(
                assessmentCompleted.assessment_marked_url,
                "[GEMERK]" +
                  assessmentCompleted.assessment_completed_filename +
                  ".pdf"
              );
            }}
          >
            Laai Gemerkde Assessering Af
          </span>
        ) : assessmentCompleted.resubmit_flag === "true" ? (
          <span
            className="text-red cursor-pointer hover:underline dark:text-red"
            onClick={() =>
              handleAssessmentClick(assessmentCompleted.assessment)
            }
          >
            Herlaai Assessering
          </span>
        ) : !isComplete(assessmentCompleted.assessment.c_datetime) ? (
          <span
            className="text-d-green cursor-pointer hover:underline dark:text-l-green"
            onClick={() =>
              handleAssessmentClick(assessmentCompleted.assessment)
            }
          >
            Herlaai Assessering
          </span>
        ) : (
          <span className="dark:text-white">Besig om gemerk te word...</span>
        )}
      </div>
    </div>
  );
};

export default AssessmentCompletedCard;
