import axios from "./axios";

interface FetchClassesRequest {
  token: string;
}

interface FetchClassesResponse {
  data: {
    status: "success" | "error";
    classes?: {
      class_id: number;
      class_title: string;
      grade: number;
      module: number;
      subject_id: number;
      teacher_id: number;
      zoom_link: string;
      zoom_meeting_id: string;
      zoom_meeting_password: string;
      o_datetime: string;
      c_datetime: string;
      date_created: string;
      teacher: string;
      subject: string;
    }[];
    errorMessage?: string;
  };
}

export async function fetchClasses(
  data: FetchClassesRequest
): Promise<FetchClassesResponse> {
  return axios.post("no/classes/fetch-classes.php", data);
}
