import { FC, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import StudentContext from "../context/StudentContext";

import { fetchRankPoints } from "../api/rankingEndpoints";

import MainLayout from "../components/MainLayout";
import ProfileCard from "../components/ProfileCard";
import Button from "../components/Button";
import StudentAchievements from "../components/StudentAchievements";
import { toast } from "react-toastify";

interface PointLog {
  log_description: string;
  points: number;
  date_created: string;
}

const ProfilePage: FC = () => {
  const navigate = useNavigate();
  const { student, token } = useContext(StudentContext);

  const [points, setPoints] = useState<PointLog[]>([]);

  useEffect(() => {
    fetchRankPoints({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.points) {
            setPoints(response.data.points);
          }
        }
      })
      .catch(console.log);
  }, [token]);

  const handleStudentLogout = () => {
    let studentTokens =
      JSON.parse(localStorage.getItem("student-tokens") as string) || [];

    localStorage.setItem(
      "student-tokens",
      JSON.stringify(
        studentTokens.filter((studentToken: string) => {
          return studentToken !== token;
        })
      )
    );

    navigate("/dashboard");
    toast.success(student.f_name + " is uitgeteken.");
  };

  return (
    <MainLayout>
      <div className="mb-16 flex flex-col md:flex-row justify-start items-center md:items-start space-y-20 md:space-y-0 md:space-x-8">
        <div>
          <ProfileCard hideBadges />

          <div className="mt-14">
            <Button
              type="solid"
              buttonText="TEKEN LEERDER UIT"
              onClick={() => {
                handleStudentLogout();
              }}
            />
          </div>
        </div>

        <div className="flex-grow">
          <StudentAchievements />
        </div>
      </div>

      {student.subscription === "enabled" ? (
        <div className="w-full py-6 px-8 bg-white rounded-lg shadow-xl dark:bg-l-gray">
          <h2 className="mb-11 font-semibold text-2xl text-black dark:text-white">
            Punte Toekennings
          </h2>

          {points.map((log: PointLog) => {
            return (
              <div className="mb-5 px-4 lg:px-8 py-4 flex flex-col lg:flex-row justify-between items-center space-y-5 lg:space-y-0 bg-gray-500 rounded-xl dark:bg-d-gray dark:text-white">
                <span className="font-bold text-base">
                  + {log.points} punte
                </span>
                <span className="text-center text-base">
                  {log.log_description}
                </span>
                <span className="text-base">{log.date_created}</span>
              </div>
            );
          })}
        </div>
      ) : null}
    </MainLayout>
  );
};

export default ProfilePage;
