/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect } from "react";
import { toast } from "react-toastify";

import StudentContext from "../context/StudentContext";

import { createBookmark, deleteBookmark } from "../api/noBookEndpoints";

import { BookmarkIcon, RemoveBookmarkIcon } from "../components/Icons";

interface ReaderControlsBookmarkToggleProps {
  book: number;
  currentPage: number;
  isBookmarked: boolean;
  bookmark?: number;
  updateBookmarks: () => void;
}

const ReaderControlsBookmarkToggle: FC<ReaderControlsBookmarkToggleProps> = ({
  book,
  currentPage,
  isBookmarked,
  bookmark,
  updateBookmarks,
}) => {
  const { token } = useContext(StudentContext);

  const handleCreateBookmark = () => {
    createBookmark({ token: token, book_id: book, page_number: currentPage })
      .then((response) => {
        if (response.data.status === "success") {
          updateBookmarks();
          toast.success("Boekmerk is geskep.");
        }
      })
      .catch(console.log);
  };

  const handleDeleteBookmark = () => {
    if (bookmark) {
      deleteBookmark({ token: token, bookmark_id: bookmark })
        .then((response) => {
          if (response.data.status === "success") {
            updateBookmarks();
            toast.success("Boekmerk is verwyder.");
          }
        })
        .catch(console.log);
    }
  };

  useEffect(() => {
    const handleShortcuts = (e: { keyCode: any }) => {
      if (e.keyCode === 66) {
        if (isBookmarked) {
          handleDeleteBookmark();
        } else {
          handleCreateBookmark();
        }
      }
    };

    document.addEventListener("keydown", handleShortcuts);

    return () => {
      document.removeEventListener("keydown", handleShortcuts);
    };
  }, [handleCreateBookmark, handleDeleteBookmark, isBookmarked]);

  return isBookmarked ? (
    <span
      className="mr-12 cursor-pointer hover:animate-pulse"
      onClick={handleDeleteBookmark}
    >
      <RemoveBookmarkIcon />
    </span>
  ) : (
    <span
      className="mr-12 cursor-pointer hover:animate-pulse"
      onClick={handleCreateBookmark}
    >
      <BookmarkIcon className="fill-black dark:fill-white" />
    </span>
  );
};

export default ReaderControlsBookmarkToggle;
