import axios from "./axios";

interface FetchOnlineBooksRequest {
  token: string;
}

interface FetchOnlineBooksResponse {
  data: {
    status: "success" | "error";
    books?: {
      book_id: string;
      book_title: string;
      grade: string;
      module: string;
      subject_id: string;
      cover_img_url: string;
      page_count: string;
      subject: string;
      price: number;
    }[];
    errorMessage?: string;
  };
}

export async function fetchOnlineBooks(
  data: FetchOnlineBooksRequest
): Promise<FetchOnlineBooksResponse> {
  return axios.post("nb/fetch-no-books.php", data);
}

interface FetchOrdersBooksRequest {
  token: string;
}

interface FetchOrdersBooksResponse {
  data: {
    status: "success" | "error";
    books?: {
      book_id: number;
      book_title: string;
      grade: number;
      category_id: number;
      cover_img_url: string;
      price: number;
      date_created: string;
      category: string;
    }[];
    errorMessage?: string;
  };
}

export async function fetchOrdersBooks(
  data: FetchOrdersBooksRequest
): Promise<FetchOrdersBooksResponse> {
  return axios.post("nb/fetch-nb-books.php", data);
}
