import { FC, useState, useEffect, useContext } from "react";

import StudentContext from "../context/StudentContext";

import { fetchCategories } from "../api/dataEndpoints";

interface Category {
  category_id: string;
  category_name: string;
}

interface FilterCategoryProps {
  setFilterCategory: (category: string) => void;
}

const FilterCategory: FC<FilterCategoryProps> = ({ setFilterCategory }) => {
  const { token } = useContext(StudentContext);

  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    fetchCategories({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.categories) {
            setCategories(response.data.categories);
          }
        }
      })
      .catch(console.log);
  }, []);

  return (
    <select
      className="cc-dropdown-arrow w-[375px] h-[50px] mr-4 pl-4 text-base bg-white cursor-pointer rounded-lg shadow-xl dark:bg-d-black dark:text-white"
      onChange={(e) => setFilterCategory(e.target.value)}
    >
      {categories.map((category) => {
        return (
          <option key={category.category_id} value={category.category_id}>
            {category.category_name}
          </option>
        );
      })}
    </select>
  );
};

export default FilterCategory;
