import { FC, useContext, useEffect, useState } from "react";

import StudentContext from "../context/StudentContext";

import {
  fetchStudentRanking,
  fetchStudentBadges,
} from "../api/studentEndpoints";

import ProgressBar from "./ProgressBar";

interface ProfileCardProps {
  hideBadges?: boolean;
}

const ProfileCard: FC<ProfileCardProps> = ({ hideBadges }) => {
  const { token, student } = useContext(StudentContext);

  const [studentRanking, setStudentRanking] = useState({
    points: 0,
    points_required: 0,
    current_rank: 0,
    current_level: 0,
    progress: 0,
  });

  const [lastAchievementBadge, setLastAchievementBadge] = useState("");
  const [challengeBadges, setChallengeBadges] = useState<string[]>([]);

  useEffect(() => {
    fetchStudentRanking({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.studentRanking) {
            setStudentRanking({
              ...response.data.studentRanking,
              progress: Math.round(
                (response.data.studentRanking.points /
                  response.data.studentRanking.points_required) *
                  100
              ),
            });
          }
        }
      })
      .catch(console.log);

    fetchStudentBadges({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          setLastAchievementBadge(
            response.data.lastAchievement.achievement_badge_url
          );

          let badges: string[] = [];
          response.data.challengeBadges.forEach((badge) =>
            badges.push(badge.challenge_badge_url)
          );

          setChallengeBadges(badges);
        }
      })
      .catch(console.log);
  }, [token]);

  return (
    <div className="w-[300px] py-6 px-4 bg-white rounded-lg shadow-xl dark:bg-l-gray">
      <div className="flex justify-start items-center">
        <span className="mx-4 py-4 px-3 border-2 border-white bg-d-green font-extrabold text-2xl text-white text-center rounded-full shadow-lg uppercase dark:border-d-gray dark:bg-l-green">
          {student.f_name[0]}
          {student.l_name[0]}
        </span>

        <div className="flex flex-col justify-center items-start dark:text-white">
          <span className="font-bold text-base">
            {student.f_name} {student.l_name}
          </span>
          <span className="font-normal text-sm text-gray-200 dark:text-white">
            Graad {student.grade}
          </span>
        </div>
      </div>

      <hr className="my-5 p-0 border border-gray-400 dark:border-d-gray" />

      <div className="w-full">
        <div className="mb-2 flex justify-between items-center">
          <span className="font-semibold text-4xl text-d-green dark:text-l-green">
            #{studentRanking.current_rank}
          </span>

          <span className="font-semibold text-black text-lg text-right dark:text-white">
            {studentRanking.current_level}

            <div className="m-0 p-0 font-light text-xs text-gray-600 dark:text-white">
              huidige valk
            </div>
          </span>
        </div>

        <ProgressBar progress={studentRanking.progress} size="small" />

        <p className="mt-2 text-center text-xs text-gray-600 dark:text-white">
          {studentRanking.points}/{studentRanking.points_required} punte
        </p>
      </div>

      {!hideBadges && lastAchievementBadge && challengeBadges.length > 0 ? (
        <div>
          <hr className="my-5 p-0 border border-gray-400 dark:border-d-gray" />

          <div className="flex justify-start items-center space-x-4">
            {lastAchievementBadge ? (
              <img
                src={lastAchievementBadge}
                alt="Last Achievement"
                width={80}
                height={80}
              />
            ) : null}

            {challengeBadges.map((badge, i) => {
              return (
                <img
                  key={i}
                  src={badge}
                  alt={"Challenge Badge " + i}
                  width={40}
                  height={40}
                />
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProfileCard;
