import axios from "./axios";

interface FetchBooksRequest {
  token: string;
}

interface FetchBooksResponse {
  data: {
    status: "success" | "error";
    books?: {
      book_id: string;
      book_title: string;
      grade: string;
      module: string;
      subject_id: string;
      cover_img_url: string;
      pdf_url: string;
      pdf_password: string;
      chapters: string;
      page_count: string;
    }[];
    errorMessage?: string;
  };
}

export async function fetchBooks(
  data: FetchBooksRequest
): Promise<FetchBooksResponse> {
  return axios.post("no/books/fetch-books.php", data);
}

interface FetchBookmarksRequest {
  token: string;
  book_id: number;
}

interface FetchBookmarksResponse {
  data: {
    status: "success" | "error";
    bookmarks?: {
      bookmark_id: number;
      page_number: number;
    }[];
    errorMessage?: string;
  };
}

export async function fetchBookmarks(
  data: FetchBookmarksRequest
): Promise<FetchBookmarksResponse> {
  return axios.post("no/books/fetch-bookmarks.php", data);
}

interface CreateBookmarkRequest {
  token: string;
  book_id: number;
  page_number: number;
}

interface CreateBookmarkResponse {
  data: {
    status: "success" | "error";
    errorMessage?: string;
  };
}

export async function createBookmark(
  data: CreateBookmarkRequest
): Promise<CreateBookmarkResponse> {
  return axios.post("no/books/create-bookmark.php", data);
}

interface DeleteBookmarkRequest {
  token: string;
  bookmark_id: number;
}

interface DeleteBookmarkResponse {
  data: {
    status: "success" | "error";
    errorMessage?: string;
  };
}

export async function deleteBookmark(
  data: DeleteBookmarkRequest
): Promise<DeleteBookmarkResponse> {
  return axios.post("no/books/delete-bookmark.php", data);
}

interface DeleteAllBookmarkRequest {
  token: string;
  book_id: number;
}

interface DeleteAllBookmarkResponse {
  data: {
    status: "success" | "error";
    errorMessage?: string;
  };
}

export async function deleteAllBookmark(
  data: DeleteAllBookmarkRequest
): Promise<DeleteAllBookmarkResponse> {
  return axios.post("no/books/delete-all-bookmarks.php", data);
}
