import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";

import { registerStudent, requestOTP } from "../api/authenticationEndpoints";

import AuthenticationLayout from "../components/AuthenticationLayout";
import FormHeading from "../components/FormHeading";
import FormInput from "../components/FormInput";
import FormDropdown from "../components/FormDropdown";
import Button from "../components/Button";
import Spinner from "../components/Spinner";

interface RegisterStudentFormFields {
  f_name: string;
  l_name: string;
  id_number: string;
  grade: string;
  parent_full_name: string;
  parent_email: string;
  parent_phone_number: string;
}

const RegisterStudentPage: FC = () => {
  const { register, handleSubmit } = useForm<RegisterStudentFormFields>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<RegisterStudentFormFields> = (formData) => {
    if (formData.id_number.length === 13) {
      registerStudent(formData)
        .then((response) => {
          if (response.data.status === "success") {
            setLoading(true);
            toast.dismiss();

            localStorage.setItem(
              "authentication-student-id",
              formData.id_number
            );

            requestOTP({ student_id_number: formData.id_number })
              .then((response) => {
                if (response.data.status === "success") {
                  localStorage.setItem(
                    "authentication-student-email",
                    response.data.parentEmail as string
                  );

                  navigate("/authenticate-otp");
                  setLoading(false);
                } else {
                  toast.error(response.data.errorMessage);
                  setLoading(false);
                }
              })
              .catch(console.log);
          } else {
            toast.error(response.data.errorMessage);
            setLoading(false);
          }
        })
        .catch(console.log);
    } else {
      toast.error("Verskaf 'n geldige ID-nommer.");
    }
  };

  const grades = [
    { name: "Graad R", value: "0" },
    { name: "Graad 1", value: "1" },
    { name: "Graad 2", value: "2" },
    { name: "Graad 3", value: "3" },
    { name: "Graad 4", value: "4" },
    { name: "Graad 5", value: "5" },
    { name: "Graad 6", value: "6" },
    { name: "Graad 7", value: "7" },
    { name: "Graad 8", value: "8" },
    { name: "Graad 9", value: "9" },
    { name: "Graad 10", value: "10" },
    { name: "Graad 11", value: "11" },
    { name: "Graad 12", value: "12" },
  ];

  return (
    <AuthenticationLayout withPrev>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="lg:w-full px-5 lg:p-0 max-w-[880px]"
      >
        <FormHeading headingText="Registreer ‘n Nuwe Leerder" />

        <div className="w-full flex flex-col lg:flex-row justify-center items-center lg:space-x-14">
          <FormInput
            label="leerder naam"
            inputType="text"
            inputName="f_name"
            formRegister={register}
          />

          <FormInput
            label="leerder van"
            inputType="text"
            inputName="l_name"
            formRegister={register}
          />
        </div>

        <div className="w-full flex flex-col lg:flex-row justify-center items-center lg:space-x-14">
          <FormInput
            label="leerder ID nommer"
            inputType="text"
            inputName="id_number"
            formRegister={register}
          />

          <FormDropdown
            label="Kies ‘n graad"
            inputName="grade"
            options={grades}
            formRegister={register}
          />
        </div>

        <hr className="mb-12 p-0 border border-gray-400 dark:border-d-gray" />

        <div className="w-full flex flex-col lg:flex-row justify-center items-center lg:space-x-14">
          <FormInput
            label="Ouer/voog naam en van"
            inputType="text"
            inputName="parent_full_name"
            formRegister={register}
          />

          <FormInput
            label="ouer/voog e-posadres"
            inputType="email"
            inputName="parent_email"
            formRegister={register}
          />
        </div>

        <div className="w-full flex flex-col lg:flex-row justify-start items-center lg:items-start lg:space-x-14">
          <FormInput
            label="Ouer/voog selfoon nommer"
            inputType="text"
            inputName="parent_phone_number"
            formRegister={register}
          />
        </div>

        <div className="flex justify-end items-center">
          {loading ? (
            <Spinner />
          ) : (
            <Button
              type="outlined"
              buttonText="Volgende"
              onClick={() => {}}
              withIcon
            />
          )}
        </div>
      </form>
    </AuthenticationLayout>
  );
};

export default RegisterStudentPage;
