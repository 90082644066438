import { FC } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import Spinner from "../components/Spinner";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface ReaderProps {
  currentPage: number;
  currentZoom: number;
  fileLink: string;
  filePassword: string;
  filePageCount: number;
  pageView: "single" | "double" | "multiple";
}

const Reader: FC<ReaderProps> = ({
  currentPage,
  currentZoom,
  fileLink,
  filePassword,
  filePageCount,
  pageView,
}) => {
  return (
    <div className="w-full min-h-full flex justify-center items-center">
      <Document
        // file="/sample.pdf"
        file={fileLink.replace("nukleusontwerp", "nukleusaanlyn")}
        onPassword={(unlock) => unlock(filePassword)}
        loading={<Spinner />}
      >
        {pageView === "single" ? (
          <Page
            pageNumber={currentPage}
            scale={currentZoom}
            loading={<div />}
          />
        ) : null}

        {pageView === "double" ? (
          <div className="flex justify-center items-center space-x-5">
            <Page
              pageNumber={currentPage}
              scale={currentZoom}
              loading={<div />}
            />
            <Page
              pageNumber={currentPage + 1}
              scale={currentZoom}
              loading={<div />}
            />
          </div>
        ) : null}

        {pageView === "multiple" ? (
          <div className="flex flex-col justify-center items-center space-y-5">
            {[...Array(Math.round(filePageCount))].map((_, page) => {
              return (
                <Page
                  key={page}
                  pageNumber={page + 1}
                  scale={currentZoom}
                  loading={<div />}
                />
              );
            })}
          </div>
        ) : null}
      </Document>
    </div>
  );
};

export default Reader;
