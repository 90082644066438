import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";

import { verifyStudentID, requestOTP } from "../api/authenticationEndpoints";

import AuthenticationLayout from "../components/AuthenticationLayout";
import FormHeading from "../components/FormHeading";
import FormInput from "../components/FormInput";
import Button from "../components/Button";
import Spinner from "../components/Spinner";

interface LoginFormFields {
  student_id_number: string;
}

const AuthenticateSIDPage: FC = () => {
  const { register, handleSubmit } = useForm<LoginFormFields>();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<LoginFormFields> = (formData) => {
    verifyStudentID(formData)
      .then((response) => {
        if (response.data.status === "success") {
          setLoading(true);
          toast.dismiss();

          localStorage.setItem(
            "authentication-student-id",
            formData.student_id_number
          );

          requestOTP(formData)
            .then((response) => {
              if (response.data.status === "success") {
                localStorage.setItem(
                  "authentication-student-email",
                  response.data.parentEmail as string
                );

                navigate("/authenticate-otp");
                setLoading(false);
              } else {
                toast.error(response.data.errorMessage);
              }
            })
            .catch(console.log);
        } else {
          toast.error(response.data.errorMessage);
          setLoading(false);
        }
      })
      .catch(console.log);
  };

  return (
    <AuthenticationLayout withPrev>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full px-5 lg:p-0 max-w-[400px]"
      >
        <FormHeading headingText="Teken Leerder In" />

        <div className="w-full flex justify-center items-center">
          <FormInput
            label="leerder ID nommer"
            inputType="text"
            inputName="student_id_number"
            formRegister={register}
          />
        </div>

        <div className="mt-10 flex justify-end items-center">
          {loading ? (
            <Spinner />
          ) : (
            <Button
              type="outlined"
              buttonText="Volgende"
              onClick={() => {}}
              withIcon
            />
          )}
        </div>
      </form>
    </AuthenticationLayout>
  );
};

export default AuthenticateSIDPage;
