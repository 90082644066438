import axios from "./axios";

interface RegisterStudentRequest {
  f_name: string;
  l_name: string;
  id_number: string;
  grade: string;
  parent_full_name: string;
  parent_email: string;
  parent_phone_number: string;
}

interface RegisterStudentResponse {
  data: {
    status: "success" | "error";
    errorMessage?: string;
  };
}

export async function registerStudent(
  data: RegisterStudentRequest
): Promise<RegisterStudentResponse> {
  return axios.post("authentication/register-student.php", data);
}

interface RequestOTPRequest {
  student_id_number: string;
}

interface RequestOTPResponse {
  data: {
    status: "success" | "error";
    parentEmail?: string;
    errorMessage?: string;
  };
}

export async function requestOTP(
  data: RequestOTPRequest
): Promise<RequestOTPResponse> {
  return axios.post("authentication/request-otp.php", data);
}

interface VerifyOTPRequest {
  student_id_number: string;
  authentication_otp: string;
}

interface VerifyOTPResponse {
  data: {
    status: "success" | "error";
    token?: string;
    errorMessage?: string;
  };
}

export async function verifyOTP(
  data: VerifyOTPRequest
): Promise<VerifyOTPResponse> {
  return axios.post("authentication/verify-otp.php", data);
}

interface VerifyStudentIDRequest {
  student_id_number: string;
}

interface VerifyStudentIDResponse {
  data: {
    status: "success" | "error";
    errorMessage?: string;
  };
}

export async function verifyStudentID(
  data: VerifyStudentIDRequest
): Promise<VerifyStudentIDResponse> {
  return axios.post("authentication/verify-student-id.php", data);
}
