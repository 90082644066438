import { FC } from "react";
import { useNavigate } from "react-router-dom";

// import { DownloadIcon, DownloadedIcon } from "../components/Icons";

interface BookCardProps {
  book: {
    book_id: string;
    book_title: string;
    grade: string;
    module: string;
    subject_id: string;
    cover_img_url: string;
    pdf_url: string;
    pdf_password: string;
    chapters: string;
    page_count: string;
  };
}

const BookCard: FC<BookCardProps> = ({ book }) => {
  const navigate = useNavigate();

  const handleReadClick = () => {
    localStorage.setItem("active-book", JSON.stringify(book));
    navigate("/no/reading");
  };

  return (
    <div className="w-[240px] mb-10 md:mr-8">
      <h3 className="mb-2 font-semibold text-center text-xs dark:text-white">
        {book.book_title}
      </h3>

      <img
        src={book.cover_img_url}
        alt={book.book_title}
        width={200}
        height={320}
        className="mx-auto rounded-lg"
      />

      <div className="relative w-[240px] -mt-10 mx-auto py-5 px-4 flex justify-center items-center bg-white rounded-lg shadow-lg dark:bg-l-gray">
        <span
          className="text-d-green cursor-pointer hover:underline dark:text-l-green"
          onClick={handleReadClick}
        >
          Begin Lees
        </span>

        {/* <span className="cursor-pointer hover:animate-pulse">
          <DownloadIcon className="fill-d-green dark:fill-l-green" />
        </span> */}
      </div>
    </div>
  );
};

export default BookCard;
