import { FC, useContext } from "react";
import { toast } from "react-toastify";

import StudentContext from "../context/StudentContext";

import { deleteAllBookmark } from "../api/noBookEndpoints";

import {
  ClearIcon,
  RArrowIcon,
  LArrowIcon,
  RemoveBookmarkIcon,
} from "../components/Icons";

interface ReaderTabSettingsProps {
  book: number;
  currentPageView: string;
  setPageView: (view: "single" | "double" | "multiple") => void;
  setShowTab: (show: boolean) => void;
  updateBookmarks: () => void;
}

const ReaderTabSettings: FC<ReaderTabSettingsProps> = ({
  book,
  currentPageView,
  setPageView,
  setShowTab,
  updateBookmarks,
}) => {
  const { token } = useContext(StudentContext);

  const handleDeleteAllBookmark = () => {
    deleteAllBookmark({ token: token, book_id: book })
      .then((response) => {
        if (response.data.status === "success") {
          updateBookmarks();
          toast.success("Alle boekmerke is verwyder.");
        }
      })
      .catch(console.log);
  };

  return (
    <div className="absolute w-[350px] h-full pt-24 pb-20 bottom-20 left-16 bg-white rounded-bl-3xl dark:bg-d-gray">
      <div className="flex justify-between items-center ml-2 mb-8 pt-4 pb-8 pl-8 pr-4 border-b border-gray-500">
        <h2 className="font-normal text-2xl text-black dark:text-white">
          Instellings
        </h2>

        <span className="mr-2 cursor-pointer hover:animate-pulse">
          <ClearIcon onClick={() => setShowTab(false)} />
        </span>
      </div>

      <div className="h-full pb-20 overflow-scroll">
        <div className="ml-5 px-5">
          <div className="mb-12 flex flex-col justify-start items-start">
            <label className="mb-2 font-medium text-xs text-h-black uppercase dark:text-white">
              Bladsyaansig
            </label>
            <select
              className="cc-dropdown-arrow w-full h-[50px] pl-4 text-base bg-white border border-gray-300 rounded-lg shadow-lg dark:bg-l-gray dark:text-white"
              onChange={(e) => {
                if (e.target.value === "single") {
                  setPageView(e.target.value);
                }

                if (e.target.value === "double") {
                  setPageView(e.target.value);
                }

                if (e.target.value === "multiple") {
                  setPageView(e.target.value);
                }
              }}
            >
              <option value="single" selected={currentPageView === "single"}>
                Enkelbladsy-aansig
              </option>
              <option value="double" selected={currentPageView === "double"}>
                Dubbelbladaansig
              </option>
              <option
                value="multiple"
                selected={currentPageView === "multiple"}
              >
                Meervoudige bladsyaansig
              </option>
            </select>
          </div>

          <div className="mt-8">
            <span className="font-medium text-xs text-h-black uppercase dark:text-white">
              Sleutelbord kortpaaie
            </span>

            <div className="mt-4 py-4 px-4 flex justify-between items-center bg-gray-500 dark:bg-l-gray dark:text-white">
              <span className="font-light text-sm">Volgende Bladsy</span>
              <span className="font-extrabold">
                <RArrowIcon className="fill-black dark:fill-white" />
              </span>
            </div>

            <div className="mt-4 py-4 px-4 flex justify-between items-center bg-gray-500 dark:bg-l-gray dark:text-white">
              <span className="font-light text-sm">Vorige Bladsy</span>
              <span className="font-extrabold">
                <LArrowIcon className="fill-black dark:fill-white" />
              </span>
            </div>

            <div className="mt-4 py-4 px-4 flex justify-between items-center bg-gray-500 dark:bg-l-gray dark:text-white">
              <span className="font-light text-sm">Vergroot</span>
              <span className="font-extrabold">i</span>
            </div>

            <div className="mt-4 py-4 px-4 flex justify-between items-center bg-gray-500 dark:bg-l-gray dark:text-white">
              <span className="font-light text-sm">Verklein</span>
              <span className="font-extrabold">u</span>
            </div>

            <div className="mt-4 py-4 px-4 flex justify-between items-center bg-gray-500 dark:bg-l-gray dark:text-white">
              <span className="font-light text-sm">
                Spring na Eerste Bladsy
              </span>
              <span className="font-extrabold">e</span>
            </div>

            <div className="mt-4 py-4 px-4 flex justify-between items-center bg-gray-500 dark:bg-l-gray dark:text-white">
              <span className="font-light text-sm">
                Spring na Laaste Bladsy
              </span>
              <span className="font-extrabold">l</span>
            </div>

            <div className="mt-4 py-4 px-4 flex justify-between items-center bg-gray-500 dark:bg-l-gray dark:text-white">
              <span className="font-light text-sm">Boekmerk</span>
              <span className="font-extrabold">b</span>
            </div>
          </div>

          <div
            className="mt-10 flex justify-center items-center text-red cursor-pointer hover:animate-pulse"
            onClick={handleDeleteAllBookmark}
          >
            <RemoveBookmarkIcon className="mr-2" />
            Verwyder Alle Boekmerke
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReaderTabSettings;
