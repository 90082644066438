import { FC } from "react";
import { AiFillNotification } from "react-icons/ai";

const NotificationAlerts: FC = () => {
  const isToday = (date: string) => {
    const d1 = new Date();
    const d2 = new Date(date);
    return d1.setHours(0, 0, 0, 0) === d2.setHours(0, 0, 0, 0);
  };

  return (
    <div className="flex flex-col justify-between items-center mb-8 space-y-4">
      {new Date().getDay() === 1 && (
        <div className="w-full py-4 px-6 bg-white rounded-lg shadow-xl dark:bg-l-gray">
          <div className="w-full flex justify-start items-center space-x-4">
            <AiFillNotification className="w-5 h-5 text-black dark:text-white opacity-80" />
            <span className="text-base text-black dark:text-white">
              Onthou ons speel 13:00 skaak.
            </span>
          </div>
        </div>
      )}

      {isToday("08-05-2024") && (
        <div className="w-full py-4 px-6 bg-white rounded-lg shadow-xl dark:bg-l-gray">
          <div className="w-full flex justify-start items-center space-x-4">
            <AiFillNotification className="w-5 h-5 text-black dark:text-white opacity-80" />
            <span className="text-base text-black dark:text-white">
              Skaak toernooi vandag 13:00
            </span>
          </div>
        </div>
      )}

      {isToday("08-12-2024") && (
        <div className="w-full py-4 px-6 bg-white rounded-lg shadow-xl dark:bg-l-gray">
          <div className="w-full flex justify-start items-center space-x-4">
            <AiFillNotification className="w-5 h-5 text-black dark:text-white opacity-80" />
            <span className="text-base text-black dark:text-white">
              Skaak toernooi vandag 13:00
            </span>
          </div>
        </div>
      )}

      {isToday("08-19-2024") && (
        <div className="w-full py-4 px-6 bg-white rounded-lg shadow-xl dark:bg-l-gray">
          <div className="w-full flex justify-start items-center space-x-4">
            <AiFillNotification className="w-5 h-5 text-black dark:text-white opacity-80" />
            <span className="text-base text-black dark:text-white">
              Skaak toernooi vandag 13:00
            </span>
          </div>
        </div>
      )}

      {isToday("08-26-2024") && (
        <div className="w-full py-4 px-6 bg-white rounded-lg shadow-xl dark:bg-l-gray">
          <div className="w-full flex justify-start items-center space-x-4">
            <AiFillNotification className="w-5 h-5 text-black dark:text-white opacity-80" />
            <span className="text-base text-black dark:text-white">
              Skaak toernooi vandag 13:00
            </span>
          </div>
        </div>
      )}

      {isToday("07-19-2024") && (
        <div className="w-full py-4 px-6 bg-white rounded-lg shadow-xl dark:bg-l-gray">
          <div className="w-full flex justify-start items-center space-x-4">
            <AiFillNotification className="w-5 h-5 text-black dark:text-white opacity-80" />
            <span className="text-base text-black dark:text-white">
              Nukleus kultuur: inskrywings vir ons kuns kompetisie open vandag.
            </span>
          </div>
        </div>
      )}

      {isToday("07-29-2024") && (
        <div className="w-full py-4 px-6 bg-white rounded-lg shadow-xl dark:bg-l-gray">
          <div className="w-full flex justify-start items-center space-x-4">
            <AiFillNotification className="w-5 h-5 text-black dark:text-white opacity-80" />
            <span className="text-base text-black dark:text-white">
              Nukleus kultuur: onthou om in te skryf vir ons kuns kompetisie.
            </span>
          </div>
        </div>
      )}

      {isToday("08-08-2024") && (
        <div className="w-full py-4 px-6 bg-white rounded-lg shadow-xl dark:bg-l-gray">
          <div className="w-full flex justify-start items-center space-x-4">
            <AiFillNotification className="w-5 h-5 text-black dark:text-white opacity-80" />
            <span className="text-base text-black dark:text-white">
              Nukleus kultuur: inskrywings vir die kuns kompetisie sluit 16
              Augustus. Skryf nou in!
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationAlerts;
