import { FC, useState, useEffect, useContext } from "react";

import StudentContext from "../context/StudentContext";

import { fetchVideos } from "../api/nfExerciseEndpoints";

import MainLayout from "../components/MainLayout";
import ExerciseCard from "../components/ExerciseCard";

interface ExerciseGalleryPageProps {}

interface Video {
  video_id: number;
  video_title: string;
  video_subtitle: string;
  vimeo_stream_link: string;
  date_created: string;
  completed: string;
}

const ExerciseGalleryPage: FC<ExerciseGalleryPageProps> = () => {
  const { token } = useContext(StudentContext);

  const [videos, setVideos] = useState<Video[]>([]);
  let lockVideos = false;

  useEffect(() => {
    fetchVideos({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.videos) {
            setVideos(response.data.videos);
          }
        }
      })
      .catch(console.log);
  }, [token]);

  return (
    <MainLayout enabledOnly>
      <div className="w-full lg:mt-14 flex flex-wrap justify-center items-center gap-8">
        {videos.map((video) => {
          let lock = lockVideos;

          if (video.completed === "false" && !lockVideos) {
            lockVideos = true;
          }

          return (
            <ExerciseCard
              key={video.video_id}
              video={video}
              isLocked={video.completed === "false" && lock}
            />
          );
        })}
      </div>
    </MainLayout>
  );
};

export default ExerciseGalleryPage;
