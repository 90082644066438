import { FC } from "react";

import MainLayout from "../components/MainLayout";
import { ComingSoonIcon } from "../components/Icons";

interface ChallengesPageProps {}

const ChallengesPage: FC<ChallengesPageProps> = () => {
  return (
    <MainLayout enabledOnly>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <ComingSoonIcon className="fill-d-green dark:fill-l-green" />

        <span className="text-center mt-10 text-4xl text-gray-100 dark:text-white">
          Uitdagings kom binnekort...
        </span>
      </div>
    </MainLayout>
  );
};

export default ChallengesPage;
