import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";

import ThemeContext from "../context/ThemeContext";

// import { DownloadIcon, DownloadedIcon } from "../components/Icons";

interface VideoCardProps {
  video: {
    video_id: number;
    video_title: string;
    grade: number;
    module: number;
    subject_id: number;
    vimeo_stream_link: string;
    vimeo_download_link: string;
    date_created: string;
    subject: string;
  };
}

const VideoCard: FC<VideoCardProps> = ({ video }) => {
  const navigate = useNavigate();

  const { theme } = useContext(ThemeContext);

  const handleWatchClick = () => {
    localStorage.setItem("active-video", JSON.stringify(video));
    navigate("/no/viewing");
  };

  return (
    <div className="w-[300px] mb-10 lg:mr-8">
      <div
        className={`${
          theme === "light" ? "cc-video-background" : "cc-video-background-dark"
        } w-[300px] h-[220px] pt-6 pb-20 px-4 text-center text-white bg-d-green rounded-lg`}
      >
        <h3 className="h-[95px] font-semibold text-xl">{video.video_title}</h3>
        <span className="font-semibold text-xs">
          {video.subject} - Module {video.module}
        </span>
      </div>

      <div className="w-[240px] -mt-9 mx-auto py-5 px-4 flex justify-center items-center bg-white rounded-lg shadow-lg dark:bg-l-gray">
        <span
          className="text-d-green cursor-pointer hover:underline dark:text-l-green"
          onClick={() => handleWatchClick()}
        >
          Kyk Opname
        </span>

        {/* <span className="cursor-pointer hover:animate-pulse">
          <DownloadIcon className="fill-d-green dark:fill-l-green" />
        </span> */}
      </div>
    </div>
  );
};

export default VideoCard;
