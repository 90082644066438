import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Loader from "../components/Loader";

interface LoadingPageProps {}

const LoadingPage: FC<LoadingPageProps> = () => {
  let navigate = useNavigate();

  useEffect(() => {
    let redirect = "/welcome";

    const studentTokens =
      JSON.parse(localStorage.getItem("student-tokens") as string) || [];
    if (studentTokens.length > 0) {
      redirect = "/dashboard";
    }

    setTimeout(() => {
      navigate(redirect);
    }, 5000);
  });

  return (
    <div className="cc-background">
      <Loader />
    </div>
  );
};

export default LoadingPage;
