import { FC, useContext, useEffect, useState } from "react";

import StudentContext from "../context/StudentContext";

import { fetchAssessmentsCompleted } from "../api/noAssessmentEndpoints";

import AssessmentCompletedCard from "./AssessmentCompletedCard";

interface AssessmentsCompletedTabProps {
  year: number;
}

interface Assessment {
  assessment_id: number;
  assessment_title: string;
  grade: number;
  module: number;
  subject_id: number;
  assessment_url: string;
  o_datetime: string;
  c_datetime: string;
  date_created: string;
  subject: string;
  completed: string;
  year: number;
}

interface AssessmentCompleted {
  assessment_completed_id: number;
  assessment_id: number;
  student_id: number;
  assessment_completed_url: string;
  assessment_completed_filename: string;
  assessment_marked_url: string;
  marked_by: number;
  resubmit_flag: string;
  date_created: string;
  year: number;
  assessment: Assessment;
}

const AssessmentsCompletedTab: FC<AssessmentsCompletedTabProps> = ({
  year,
}) => {
  const { token } = useContext(StudentContext);

  const [assessmentsCompleted, setAssessmentsCompleted] = useState<
    AssessmentCompleted[]
  >([]);

  useEffect(() => {
    fetchAssessmentsCompleted({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.assessmentsCompleted) {
            setAssessmentsCompleted(response.data.assessmentsCompleted);
          }
        }
      })
      .catch(console.log);
  }, [token]);

  const handleFilterAssessments = (assessment: AssessmentCompleted) => {
    return assessment.assessment.year.toString() === year.toString();
  };

  return (
    <div className="max-w-[1250px] mx-auto">
      {assessmentsCompleted
        .filter(handleFilterAssessments)
        .map((assessmentCompleted) => {
          return (
            <AssessmentCompletedCard
              assessmentCompleted={assessmentCompleted}
            />
          );
        })}
    </div>
  );
};

export default AssessmentsCompletedTab;
