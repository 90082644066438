import { FC, useContext, useState, useEffect } from "react";

import StudentContext from "../context/StudentContext";

import { fetchStudentSchedule } from "../api/studentEndpoints";

import { ScheduleEmptyIcon } from "../components/Icons";
import StudentScheduleCard from "./StudentScheduleCard";

interface ScheduleActivity {
  type: "class" | "assessment";
  oTime: string;
  cTime: string;
  title: string;
  classLink: string;
  assessment: {};
}

const StudentSchedule: FC = () => {
  const { token, student } = useContext(StudentContext);

  const [schedule, setSchedule] = useState<ScheduleActivity[]>([]);

  useEffect(() => {
    fetchStudentSchedule({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          let newSchedule: ScheduleActivity[] = [];

          if (response.data.classes) {
            response.data.classes.forEach((classActivity) => {
              newSchedule.push({
                type: "class",
                oTime: classActivity.o_datetime,
                cTime: classActivity.c_datetime,
                title:
                  classActivity.class_title + " - " + classActivity.teacher,
                classLink: classActivity.zoom_link,
                assessment: {},
              });
            });
          }

          if (response.data.assessments) {
            response.data.assessments.forEach((assessmentActivity) => {
              newSchedule.push({
                type: "assessment",
                oTime: assessmentActivity.o_datetime,
                cTime: assessmentActivity.c_datetime,
                title: assessmentActivity.assessment_title,
                classLink: "",
                assessment: assessmentActivity,
              });
            });
          }

          setSchedule(newSchedule);
        }
      })
      .catch(console.log);
  }, [token]);

  useEffect(() => {
    const refresh = setInterval(() => {
      fetchStudentSchedule({ token: token })
        .then((response) => {
          if (response.data.status === "success") {
            let newSchedule: ScheduleActivity[] = [];

            if (response.data.classes) {
              response.data.classes.forEach((classActivity) => {
                newSchedule.push({
                  type: "class",
                  oTime: classActivity.o_datetime,
                  cTime: classActivity.c_datetime,
                  title:
                    classActivity.class_title + " - " + classActivity.teacher,
                  classLink: classActivity.zoom_link,
                  assessment: {},
                });
              });
            }

            if (response.data.assessments) {
              response.data.assessments.forEach((assessmentActivity) => {
                newSchedule.push({
                  type: "assessment",
                  oTime: assessmentActivity.o_datetime,
                  cTime: assessmentActivity.c_datetime,
                  title: assessmentActivity.assessment_title,
                  classLink: "",
                  assessment: assessmentActivity,
                });
              });
            }

            setSchedule(newSchedule);
          }
        })
        .catch(console.log);
    }, 4000);

    return () => clearInterval(refresh);
  }, [token]);

  const handleSortSchedule = (a: ScheduleActivity, b: ScheduleActivity) => {
    return new Date(a.oTime).valueOf() - new Date(b.oTime).valueOf();
  };

  const isActivityComplete = (timeString: string) => {
    return new Date().valueOf() > new Date(timeString).valueOf();
  };

  return (
    <div className="w-full py-6 px-8 bg-white rounded-lg shadow-xl dark:bg-l-gray">
      <h2 className="text-center md:text-left mb-11 font-semibold text-2xl text-black dark:text-white">
        {student.f_name} se Skedule
      </h2>

      {schedule.length === 0 ? (
        <div className="my-10 flex flex-col justify-center items-center">
          <ScheduleEmptyIcon className="fill-d-green dark:fill-l-green" />

          <p className="mt-8 text-center text-base text-black dark:text-white">
            Jou dag is heeltemal oop, gaan doen iets wonderliks.
          </p>
        </div>
      ) : null}

      {schedule.sort(handleSortSchedule).map((activity) => {
        return (
          <StudentScheduleCard
            type={activity.type}
            title={activity.title}
            oTime={activity.oTime}
            cTime={activity.cTime}
            isReady={isActivityComplete(activity.oTime)}
            isComplete={isActivityComplete(activity.cTime)}
            classLink={activity.classLink}
            assessment={activity.assessment}
          />
        );
      })}

      {schedule.length > 0 &&
      isActivityComplete(
        schedule.sort(handleSortSchedule)[schedule.length - 1].cTime
      ) ? (
        <p className="mt-12 text-base text-black dark:text-white">
          Klaar vir vandag! Geniet jou middag.
        </p>
      ) : null}
    </div>
  );
};

export default StudentSchedule;
