import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useDropzone } from "react-dropzone";

import StudentContext from "../context/StudentContext";

import {
  completeAssessment,
  uploadAssessments,
} from "../api/noAssessmentEndpoints";

import Button from "../components/Button";
import {
  BackIcon,
  AssessmentTimerIcon,
  AssessmentUploadIcon,
  AssessmentCompleteIcon,
} from "../components/Icons";
import { toast } from "react-toastify";

interface TestingPageProps {}

const TestingPage: FC<TestingPageProps> = () => {
  const navigate = useNavigate();

  const { token, assignStudentPoints } = useContext(StudentContext);

  const assessment = JSON.parse(
    localStorage.getItem("active-assessment") as string
  );

  const [uploadedAssessment, setUploadedAssessment] = useState({
    fileName: "",
    url: "",
  });

  const getTimeRemaining = (cTimeString: string) => {
    const currentDate = new Date();
    const cDate = new Date(cTimeString);

    const remainingH = Math.floor(
      (cDate.valueOf() - currentDate.valueOf()) / 3600000
    );

    const remainingM = Math.round(
      Math.round((cDate.valueOf() - currentDate.valueOf()) % 3600000) / 60000
    );

    const remainingS = Math.round(
      (Math.round((cDate.valueOf() - currentDate.valueOf()) % 3600000) %
        60000) /
        1000
    );

    return (
      (remainingH < 10 ? "0" + remainingH : remainingH) +
      ":" +
      (remainingM < 10 ? "0" + remainingM : remainingM) +
      ":" +
      (remainingS < 10 ? "0" + remainingS : remainingS)
    );
  };

  const [remainingTime, setRemaingTime] = useState(
    getTimeRemaining(assessment.c_datetime)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaingTime(getTimeRemaining(assessment.c_datetime));
    }, 1000);
    return () => clearInterval(interval);
  });

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const formData = new FormData();

      formData.append("token", token);
      formData.append("assessment_id", assessment.assessment_id);
      formData.append("assessment_completed", acceptedFiles[0]);

      uploadAssessments(formData)
        .then((response) => {
          if (response.data.status === "success") {
            if (
              response.data.assessmentFileName &&
              response.data.assessmentFileURL
            ) {
              setUploadedAssessment({
                fileName: response.data.assessmentFileName,
                url: response.data.assessmentFileURL,
              });

              toast.success("Assessering opgelaai!");
            }
          } else {
            toast.error("Assessering oplaai fout! Probeer weer.");
          }
        })
        .catch(console.log);
    },
    [assessment.assessment_id, token]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleCompleteAssessment = () => {
    completeAssessment({
      token: token,
      assessment_id: assessment.assessment_id,
      assessment_file_name: uploadedAssessment.fileName,
      assessment_file_url: uploadedAssessment.url,
    })
      .then((response) => {
        if (response.data.status === "success") {
          navigate("/no/studies");
          toast.success("Assessering voltooi!");

          assignStudentPoints(
            100,
            assessment.assessment_title + " (assessering voltooi)",
            "academics"
          );
        } else {
          toast.error("Assessering oplaai fout! Probeer weer.");
        }
      })
      .catch(console.log);
  };

  return (
    <div className="w-screen h-screen overflow-hidden dark:bg-l-gray">
      <div className="h-full -mt-20 flex justify-start items-stretch">
        <div className="w-20 h-full pt-20 bg-white dark:bg-l-gray">
          <div className="pt-8 flex justify-center items-center">
            <span
              className="cursor-pointer hover:animate-pulse"
              onClick={() => navigate(-1)}
            >
              <BackIcon className="fill-black dark:fill-white" />
            </span>
          </div>
        </div>

        <div className="w-full h-full mb-20 pt-32 pb-12 px-12 bg-gray-500 overflow-scroll rounded-bl-3xl dark:bg-d-gray">
          {/* <div className="absolute top-5 right-5 flex justify-center items-center text-right space-x-4">
            <AssessmentTimerIcon className="fill-gray-600 dark:fill-white" />
            <span className="w-[155px] text-4xl text-gray-600 text-left dark:text-white">
              {remainingTime}
            </span>
          </div> */}

          <div className="w-full h-full pt-10 px-16 flex flex-col justify-center items-center">
            <h1 className="mb-12 font-semibold text-3xl text-black dark:text-white">
              Assessering het begin...
            </h1>

            <p className="w-[650px] text-center text-gray-200 dark:text-white">
              Begin deur die vreastel hieronder af te laai, met die Laai
              Vraestel Af knoppie. Voltooi dan die vraestel en laai weer op met
              die Oplaai blok hieronder en druk Dien Assessering In.
            </p>

            <div className="my-16">
              <Button
                type={"solid"}
                buttonText="Laai Assessering Af"
                onClick={() => {
                  saveAs(
                    assessment.assessment_url,
                    assessment.assessment_title + ".pdf"
                  );

                  toast.success("Assessering afgelaai.");
                }}
              />
            </div>

            {uploadedAssessment.fileName && uploadedAssessment.url ? (
              <div className="w-[1000px] h-[350px] flex flex-col justify-evenly items-center bg-white rounded-lg shadow-xl dark:bg-l-gray">
                <AssessmentCompleteIcon className="fill-d-green dark:fill-l-green" />

                <span className="font-medium text-xl text-d-green dark:text-l-green">
                  Assessering Opgelaai!
                </span>

                <Button
                  type={"outlined"}
                  buttonText="Dien Assessering In"
                  onClick={handleCompleteAssessment}
                />

                <span
                  className="text-d-green cursor-pointer hover:underline dark:text-l-green"
                  onClick={() =>
                    setUploadedAssessment({
                      fileName: "",
                      url: "",
                    })
                  }
                >
                  Herlaai Assessering
                </span>
              </div>
            ) : (
              <div
                className="w-[1000px] h-[350px] flex flex-col justify-evenly items-center bg-white border border-gray-600 border-dotted rounded-lg shadow-xl dark:bg-l-gray"
                {...getRootProps()}
              >
                <AssessmentUploadIcon className="fill-d-green dark:fill-l-green" />

                <input {...getInputProps()} />
                <span className="font-medium text-xl text-black dark:text-white">
                  {isDragActive ? (
                    <p>Los Lêer Hier...</p>
                  ) : (
                    <p>Sleep en Los Lêer Hier</p>
                  )}
                </span>
                <span className="font-medium text-xl text-gray-600">OF</span>

                <Button
                  type={"outlined"}
                  buttonText="Kies 'n Lêer op jou Rekenaar"
                  onClick={() => {}}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="relative w-full h-20 bg-white dark:bg-l-gray dark:text-white">
        <div className="h-full pl-20 pr-10 flex justify-between items-center">
          <span>{assessment.assessment_title}</span>
          <span>
            {assessment.subject} - Module {assessment.module}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TestingPage;
