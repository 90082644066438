import { FC } from "react";
import { useNavigate, useMatch } from "react-router-dom";

import {
  SidebarDashboardIcon,
  SidebarNOIcon,
  SidebarNFIcon,
  SidebarNBIcon,
  SidebarRankingIcon,
  SidebarProfileIcon,
} from "./Icons";

interface NavigationSidebarItemProps {
  route: string;
  match: string;
  navigationIcon: string;
}

const NavigationSidebarItem: FC<NavigationSidebarItemProps> = ({
  route,
  match,
  navigationIcon,
}) => {
  const navigate = useNavigate();
  const isActive = useMatch(match);

  const containerStyle = isActive
    ? "w-full pr-1 border-l-4 border-d-green"
    : "cursor-pointer group";

  const iconStyle = isActive
    ? "mx-auto fill-d-green"
    : "fill-i-black group-hover:animate-pulse dark:fill-white";

  return (
    <span
      className={containerStyle}
      onClick={() => {
        navigate(route);
      }}
    >
      {navigationIcon === "dashboard" ? (
        <SidebarDashboardIcon className={iconStyle} />
      ) : null}

      {navigationIcon === "no" ? <SidebarNOIcon className={iconStyle} /> : null}

      {navigationIcon === "nf" ? <SidebarNFIcon className={iconStyle} /> : null}

      {navigationIcon === "nb" ? <SidebarNBIcon className={iconStyle} /> : null}

      {navigationIcon === "ranking" ? (
        <SidebarRankingIcon className={iconStyle} />
      ) : null}

      {navigationIcon === "profile" ? (
        <SidebarProfileIcon className={iconStyle} />
      ) : null}
    </span>
  );
};

export default NavigationSidebarItem;
