import { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  ExerciseCompleteIcon,
  ExerciseIcon,
  ExerciseLockedIcon,
} from "./Icons";

interface ExerciseCardProps {
  video: {
    video_id: number;
    video_title: string;
    video_subtitle: string;
    vimeo_stream_link: string;
    date_created: string;
    completed: string;
  };
  isLocked: boolean;
}

const ExerciseCard: FC<ExerciseCardProps> = ({ video, isLocked }) => {
  const navigate = useNavigate();

  const handleWatchClick = () => {
    localStorage.setItem("active-video", JSON.stringify(video));
    navigate("/nf/viewing");
  };

  return (
    <div className="w-full md:max-w-[300px]">
      <div className="w-full md:max-w-[300px] h-[220px] pb-20 text-center text-black bg-white rounded-lg dark:bg-l-gray dark:text-white">
        {isLocked ? (
          <div className="pt-10 mb-4 flex justify-center items-center py-2 bg-white rounded-t-lg dark:bg-l-gray">
            <ExerciseLockedIcon className="fill-black dark:fill-white" />
          </div>
        ) : video.completed === "true" ? (
          <div className="mb-4 flex justify-center items-center py-2 bg-d-green rounded-t-lg">
            <ExerciseCompleteIcon className="fill-white" />
          </div>
        ) : (
          <div className="mb-4 flex justify-center items-center py-4 bg-white rounded-t-lg dark:bg-l-gray">
            <ExerciseIcon className="fill-d-green dark:fill-l-green" />
          </div>
        )}

        <h3 className="mb-4 font-semibold text-xl">{video.video_title}</h3>
        <span className="font-semibold">{video.video_subtitle}</span>
      </div>

      {!isLocked ? (
        <div className="w-full md:max-w-[300px] -mt-9 mx-auto py-5 px-4 flex justify-center items-center bg-white rounded-lg shadow-lg dark:bg-l-gray">
          <span
            className="text-d-green cursor-pointer hover:underline dark:text-l-green"
            onClick={() => handleWatchClick()}
          >
            {video.completed === "true" ? "Herhaal Oefening" : "Begin Oefening"}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default ExerciseCard;
