import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import StudentContext from "../context/StudentContext";

import { checkPayment } from "../api/paymentEndpoints";

import { BasketIcon, ClearIcon, CardIcon } from "../components/Icons";
import Spinner from "./Spinner";

interface OnlineBookCartProps {
  cart: Book[];
  removeFromCart: (bookID: number) => void;
  hideCart: () => void;
}

interface Book {
  book_id: string;
  book_title: string;
  grade: string;
  module: string;
  subject_id: string;
  cover_img_url: string;
  page_count: string;
  subject: string;
  price: number;
}

const OnlineBookCart: FC<OnlineBookCartProps> = ({
  cart,
  removeFromCart,
  hideCart,
}) => {
  const navigate = useNavigate();
  const { token, student } = useContext(StudentContext);

  const [paymentPending, setPaymentPending] = useState(false);

  const getCheckoutBooks = () => {
    let books: number[] = [];

    cart.forEach((book) => {
      books.push(parseInt(book.book_id));
    });

    return books.join(";");
  };

  const getCheckoutPrice = () => {
    return cart
      .reduce((accumulator, object) => {
        return accumulator + object.price;
      }, 0)
      .toFixed(2);
  };

  const handlePaymentStarted = () => {
    setPaymentPending(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (paymentPending) {
        checkPayment({ token: token })
          .then((response) => {
            if (response.data.status === "success") {
              navigate("/nb/thanks");
            }
          })
          .catch(console.log);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [navigate, paymentPending, token]);

  return (
    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
      <div
        className="w-full h-full bg-black bg-opacity-80"
        onClick={() => hideCart()}
      />

      <div className="w-[750px] h-full p-10 bg-white overflow-scroll dark:bg-l-gray">
        <div className="flex justify-between items-center pb-8 border-b border-gray-500">
          <div className="flex justify-center items-center space-x-5">
            <BasketIcon className="fill-d-green dark:fill-l-green" />
            <span className="font-semibold text-2xl text-d-green dark:text-l-green">
              Mandjie
            </span>
          </div>

          <ClearIcon
            className="cursor-pointer hover:animate-pulse fill-black dark:fill-white"
            onClick={() => hideCart()}
          />
        </div>

        <div className="mt-4 mb-14 flex justify-between items-center space-x-8">
          <div className="flex justify-center items-center space-x-8">
            <span className="text-black dark:text-white">
              Totaal: R
              {cart
                .reduce((accumulator, object) => {
                  return accumulator + object.price;
                }, 0)
                .toFixed(2)}
            </span>
            <span className="text-black dark:text-white">
              {cart.length} Boeke
            </span>
          </div>

          <form
            action="https://nukleusontwerp.co.za/payments/bookstore/online-bookstore-redirect.php"
            method="post"
            target="_blank"
            onSubmit={handlePaymentStarted}
          >
            <input type="hidden" name="student_id" value={student.student_id} />
            <input type="hidden" name="student_f_name" value={student.f_name} />
            <input type="hidden" name="student_l_name" value={student.l_name} />
            <input
              type="hidden"
              name="parent_email"
              value={student.parent_email}
            />

            <input type="hidden" name="books" value={getCheckoutBooks()} />
            <input type="hidden" name="price" value={getCheckoutPrice()} />

            {paymentPending ? (
              <Spinner />
            ) : (
              <div className="min-w-[200px]  p-[2px] bg-gradient-to-b from-l-green to-d-green rounded-lg hover:bg-none hover:bg-d-green hover:shadow-lg group">
                <button
                  type="submit"
                  className="min-w-[200px] flex justify-evenly items-center max-h-[46px] py-3 text-center text-base bg-white rounded-md group-hover:bg-transparent dark:bg-l-gray disabled:hidden"
                  disabled={cart.length === 0}
                >
                  <CardIcon className="fill-d-green group-hover:fill-white" />

                  <span className="text-transparent bg-clip-text bg-gradient-to-b from-l-green to-d-green group-hover:text-white">
                    Vorder na Betaaling
                  </span>
                </button>
              </div>
            )}
          </form>
        </div>

        {cart.map((book) => {
          return (
            <div className="mt-4 py-4 px-4 flex justify-between items-center bg-gray-500 dark:text-white rounded-lg dark:bg-d-gray">
              <div className="flex justify-center items-center font-light text-sm">
                <img
                  src={book.cover_img_url}
                  alt={book.book_title}
                  width={50}
                  height={100}
                  className="mr-8 rounded-lg"
                />

                <div>
                  <h3 className="mb-2 font-semibold text-center dark:text-white">
                    {book.book_title}
                  </h3>
                  <span className="font-semibold text-xs">
                    R{book.price.toFixed(2)}
                  </span>
                </div>
              </div>

              <span>
                <ClearIcon
                  className="cursor-pointer hover:animate-pulse fill-red"
                  onClick={() => removeFromCart(parseInt(book.book_id))}
                />
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OnlineBookCart;
