import { FC, useState, useEffect, useContext } from "react";

import StudentContext from "../context/StudentContext";

import { fetchOrdersBooks } from "../api/nbBookEndpoints";

import MainLayout from "../components/MainLayout";
import FilterCategory from "../components/FilterCategory";
import OrdersBookCard from "../components/OrdersBookCard";
import { BasketIcon } from "../components/Icons";
import OrdersBookCart from "../components/OrdersBookCart";

interface BookstoreOrdersPageProps {}

interface Book {
  book_id: number;
  book_title: string;
  grade: number;
  category_id: number;
  cover_img_url: string;
  price: number;
  date_created: string;
  category: string;
}

const BookstoreOrdersPage: FC<BookstoreOrdersPageProps> = () => {
  const { token } = useContext(StudentContext);

  const [books, setBooks] = useState<Book[]>([]);

  const [cart, setCart] = useState<Book[]>([]);
  const [showCartModal, setShowCartModal] = useState(false);

  const [filterCategory, setFilterCategory] = useState("1");

  useEffect(() => {
    fetchOrdersBooks({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.books) {
            setBooks(response.data.books);
          }
        }
      })
      .catch(console.log);
  }, [token]);

  const handleFilterBooks = (book: Book) => {
    return book.category_id.toString() === filterCategory;
  };

  const addToCart = (book: Book) => {
    setCart([...cart, book]);
  };

  const removeFromCart = (bookID: number) => {
    setCart(
      cart.filter((book: Book) => {
        return book.book_id !== bookID;
      })
    );
  };

  const handleInCart = (bookID: number) => {
    const book = cart.filter((book: Book) => {
      return book.book_id === bookID;
    });

    if (book.length === 1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <MainLayout>
      <div className="mb-20">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-start">
            <FilterCategory setFilterCategory={setFilterCategory} />
          </div>

          <div className="flex justify-center items-center space-x-8">
            <span className="text-black dark:text-white">
              Totaal: R
              {cart
                .reduce((accumulator, object) => {
                  return accumulator + object.price;
                }, 0)
                .toFixed(2)}
            </span>

            <div
              className="min-w-[150px]  p-[2px] bg-gradient-to-b from-l-green to-d-green rounded-lg hover:bg-none hover:bg-d-green hover:shadow-lg group"
              onClick={() => setShowCartModal(true)}
            >
              <button className="min-w-[150px] flex justify-evenly items-center max-h-[46px] py-3 text-center text-base bg-gray-500 rounded-md group-hover:bg-transparent dark:bg-d-gray">
                <BasketIcon className="fill-d-green group-hover:fill-white" />

                <span className="text-transparent bg-clip-text bg-gradient-to-b from-l-green to-d-green group-hover:text-white">
                  Mandjie
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-start items-center">
        {books.filter(handleFilterBooks).map((book) => {
          return (
            <OrdersBookCard
              key={book.book_id}
              book={book}
              inCart={handleInCart(book.book_id)}
              addToCart={addToCart}
            />
          );
        })}

        {books.filter(handleFilterBooks).length === 0 ? (
          <p className="w-full mt-10 text-black dark:text-white text-center">
            Ons het geen boeke in hierdie graad en reeks gevind wat bestel kan
            word nie.
          </p>
        ) : null}
      </div>

      {showCartModal ? (
        <OrdersBookCart
          cart={cart}
          hideCart={() => setShowCartModal(false)}
          removeFromCart={removeFromCart}
        />
      ) : null}
    </MainLayout>
  );
};

export default BookstoreOrdersPage;
