import axios from "./axios";

interface FetchVideosRequest {
  token: string;
}

interface FetchVideosResponse {
  data: {
    status: "success" | "error";
    videos?: {
      video_id: number;
      video_title: string;
      grade: number;
      module: number;
      subject_id: number;
      vimeo_stream_link: string;
      vimeo_download_link: string;
      date_created: string;
      subject: string;
    }[];
    errorMessage?: string;
  };
}

export async function fetchVideos(
  data: FetchVideosRequest
): Promise<FetchVideosResponse> {
  return axios.post("no/videos/fetch-videos.php", data);
}
