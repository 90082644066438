import { FC } from "react";

import { useLocation, useNavigate } from "react-router-dom";

interface NavigationBarDropdownProps {
  options: { label: string; route: string }[];
}

const NavigationBarDropdown: FC<NavigationBarDropdownProps> = ({ options }) => {
  const navigate = useNavigate();
  const currentRoute = useLocation().pathname;

  return (
    <select
      className="inline lg:hidden cc-dropdown-arrow w-[220px] h-[50px] mr-4 pl-4 text-base bg-white cursor-pointer border border-[#EEE] dark:border-0 dark:bg-d-black dark:text-white rounded-3xl"
      onChange={(e) => navigate(e.target.value)}
    >
      {options.map((option) => {
        return (
          <option
            key={option.label}
            value={option.route}
            selected={option.route === currentRoute}
          >
            {option.label}
          </option>
        );
      })}
    </select>
  );
};

export default NavigationBarDropdown;
