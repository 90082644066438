/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import StudentContext from "../context/StudentContext";

import { fetchBookmarks } from "../api/noBookEndpoints";

import Reader from "../components/Reader";
import ReaderSidebar from "../components/ReaderSidebar";
import ReaderTabChapters from "../components/ReaderTabChapters";
import ReaderTabBookmark from "../components/ReaderTabBookmark";
import ReaderTabSettings from "../components/ReaderTabSettings";
import ReaderControlsPage from "../components/ReaderControlsPage";
import ReaderControlsZoom from "../components/ReaderControlsZoom";
import ReaderControlsBookmarkToggle from "../components/ReaderControlsBookmarkToggle";
import { BackIcon } from "../components/Icons";

interface ReadingPageProps {}

interface Bookmark {
  bookmark_id: number;
  page_number: number;
}

const ReadingPage: FC<ReadingPageProps> = () => {
  const navigate = useNavigate();
  const { token } = useContext(StudentContext);

  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);

  const book = JSON.parse(localStorage.getItem("active-book") as string);

  const [page, setPage] = useState(1);
  const [zoom, setZoom] = useState(0.8);

  const [showTabChapters, setShowTabChapters] = useState(false);
  const [showTabBookmark, setShowTabBookmark] = useState(false);
  const [showTabSettings, setShowTabSettings] = useState(false);

  const [pageView, setPageView] = useState<"single" | "double" | "multiple">(
    "single"
  );

  const handleFetchBookmarks = () => {
    fetchBookmarks({ token: token, book_id: book.book_id })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.bookmarks) {
            setBookmarks(response.data.bookmarks);
          } else {
            setBookmarks([]);
          }
        } else {
          setBookmarks([]);
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    handleFetchBookmarks();
  }, []);

  return (
    <div className="w-screen h-screen overflow-hidden dark:bg-d-gray">
      <div className="h-full -mt-20 flex justify-start items-stretch">
        <div className="w-20 h-full pt-20 bg-white dark:bg-d-gray">
          <div className="pt-8 flex justify-center items-center">
            <span
              className="cursor-pointer hover:animate-pulse"
              onClick={() => navigate(-1)}
            >
              <BackIcon className="fill-black dark:fill-white" />
            </span>
          </div>

          <ReaderSidebar
            showTabChapters={showTabChapters}
            showTabBookmark={showTabBookmark}
            showTabSettings={showTabSettings}
            setShowTabChapters={setShowTabChapters}
            setShowTabBookmark={setShowTabBookmark}
            setShowTabSettings={setShowTabSettings}
          />
        </div>

        <div className="w-full h-full mb-20 pt-28 pb-8 px-12 bg-gray-500 overflow-scroll rounded-bl-3xl dark:bg-l-gray">
          <Reader
            currentPage={page}
            currentZoom={zoom}
            fileLink={book.pdf_url}
            filePassword={book.pdf_password}
            filePageCount={book.page_count}
            pageView={pageView}
          />
        </div>

        {showTabChapters ? (
          <ReaderTabChapters
            chapters={book.chapters}
            setPage={setPage}
            setShowTab={setShowTabChapters}
          />
        ) : null}

        {showTabBookmark ? (
          <ReaderTabBookmark
            bookmarks={bookmarks}
            setPage={setPage}
            setShowTab={setShowTabBookmark}
          />
        ) : null}

        {showTabSettings ? (
          <ReaderTabSettings
            book={book.book_id}
            currentPageView={pageView}
            setPageView={setPageView}
            setShowTab={setShowTabSettings}
            updateBookmarks={handleFetchBookmarks}
          />
        ) : null}
      </div>

      <div className="relative w-full h-20 bg-white dark:bg-d-gray dark:text-white">
        <div className="h-full pl-20 pr-10 flex justify-between items-center">
          <span>{book.book_title}</span>

          <ReaderControlsPage
            page={page}
            pageCount={book.page_count}
            setPage={setPage}
          />

          <div className="flex justify-center items-center">
            <ReaderControlsBookmarkToggle
              book={book.book_id}
              currentPage={page}
              isBookmarked={bookmarks.some((e) => e.page_number === page)}
              bookmark={
                bookmarks.filter((e) => e.page_number === page)[0]?.bookmark_id
              }
              updateBookmarks={handleFetchBookmarks}
            />

            <ReaderControlsZoom zoom={zoom} setZoom={setZoom} />

            <span className="w-[150px] ml-12 font-normal text-base">
              bladsy <strong>{page}</strong> van{" "}
              <strong>{book.page_count}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadingPage;
