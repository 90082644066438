/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from "react";

import {
  BookZoomIncreaseIcon,
  BookZoomDecreaseIcon,
} from "../components/Icons";

interface ReaderControlsZoomProps {
  zoom: number;
  setZoom: (zoom: number) => void;
}

const ReaderControlsZoom: FC<ReaderControlsZoomProps> = ({ zoom, setZoom }) => {
  const handleZoomIncrease = () => {
    if (zoom < 1.6) {
      setZoom(zoom + 0.2);
    }
  };

  const handleZoomDecrease = () => {
    if (zoom > 0.8) {
      setZoom(zoom - 0.2);
    }
  };

  useEffect(() => {
    const handleShortcuts = (e: { keyCode: any }) => {
      if (e.keyCode === 73) {
        handleZoomIncrease();
      }

      if (e.keyCode === 85) {
        handleZoomDecrease();
      }
    };

    document.addEventListener("keydown", handleShortcuts);

    return () => {
      document.removeEventListener("keydown", handleShortcuts);
    };
  }, [handleZoomDecrease, handleZoomIncrease]);

  return (
    <div className="flex justify-center items-center space-x-6">
      <span
        className="cursor-pointer hover:animate-pulse"
        onClick={handleZoomDecrease}
      >
        <BookZoomDecreaseIcon className="fill-black dark:fill-white" />
      </span>

      <span
        className="cursor-pointer hover:animate-pulse"
        onClick={handleZoomIncrease}
      >
        <BookZoomIncreaseIcon className="fill-black dark:fill-white" />
      </span>
    </div>
  );
};

export default ReaderControlsZoom;
