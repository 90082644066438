import { FC } from "react";

import { NextIcon } from "./Icons";

interface ButtonProps {
  type: "solid" | "outlined";
  buttonText: string;
  onClick: () => void;
  withIcon?: boolean;
}

const Button: FC<ButtonProps> = ({ type, buttonText, onClick, withIcon }) => {
  return type === "solid" ? (
    <button
      className="min-w-[300px] max-h-[50px] py-3 text-center text-base text-white bg-gradient-to-b from-l-green to-d-green rounded-lg hover:shadow-lg hover:bg-none hover:bg-d-green"
      onClick={onClick}
    >
      {buttonText}
    </button>
  ) : (
    <div
      className={`${
        withIcon ? "min-w-[200px]" : "min-w-[300px]"
      }  p-[2px] bg-gradient-to-b from-l-green to-d-green rounded-lg hover:bg-none hover:bg-d-green hover:shadow-lg group`}
      onClick={onClick}
    >
      <button
        className={`${
          withIcon ? "min-w-[200px]" : "min-w-[300px]"
        } flex justify-evenly items-center max-h-[46px] py-3 text-center text-base bg-white rounded-md group-hover:bg-transparent dark:bg-l-gray`}
      >
        <span className="text-transparent bg-clip-text bg-gradient-to-b from-l-green to-d-green group-hover:text-white">
          {buttonText}
        </span>

        {withIcon ? (
          <NextIcon className="fill-d-green group-hover:fill-white" />
        ) : null}
      </button>
    </div>
  );
};

export default Button;
