import { FC, useState } from "react";

import MainLayout from "../components/MainLayout";
import DateTime from "../components/DateTime";
import TabToggle from "../components/TabToggle";
import AssessmentsTab from "../components/AssessmentsTab";
import AssessmentsCompletedTab from "../components/AssessmentsCompletedTab";

interface StudiesPageProps {}

const StudiesPage: FC<StudiesPageProps> = () => {
  const [tab, setTab] = useState("assessments");
  const [year, setYear] = useState("2024");

  return (
    <MainLayout>
      <div className="mb-20 flex flex-col lg:flex-row justify-between items-center">
        <div className="flex justify-between items-center">
          <TabToggle activeTab={tab} updateTab={setTab} />

          {tab === "assessments-completed" ? (
            <select
              className="shadow-lg cc-dropdown-arrow w-[175px] h-[50px] mr-4 pl-6 text-base bg-white cursor-pointer border border-[#EEE] dark:border-0 dark:bg-d-black dark:text-white rounded-3xl"
              onChange={(e) => {
                setYear(e.target.value);
              }}
            >
              <option value="2021" selected={year === "2021"}>
                2021
              </option>
              <option value="2022" selected={year === "2022"}>
                2022
              </option>
              <option value="2023" selected={year === "2023"}>
                2023
              </option>
              <option value="2024" selected={year === "2024"}>
                2024
              </option>
            </select>
          ) : null}
        </div>

        <span className="hidden lg:inline">
          <DateTime />
        </span>
      </div>

      {tab === "assessments" ? (
        <AssessmentsTab />
      ) : (
        <AssessmentsCompletedTab year={parseInt(year)} />
      )}
    </MainLayout>
  );
};

export default StudiesPage;
