import { FC, useState, useEffect, useContext } from "react";

import StudentContext from "../context/StudentContext";

import { fetchStudentRanking } from "../api/studentEndpoints";
import { fetchRankList } from "../api/rankingEndpoints";

import MainLayout from "../components/MainLayout";
import ProgressBar from "../components/ProgressBar";

interface Rank {
  student_id: string;
  points: string;
  current_rank: string;
  student: {
    f_name: string;
    l_name: string;
  };
}

const RankingPage: FC = () => {
  const { token } = useContext(StudentContext);

  const [studentRanking, setStudentRanking] = useState({
    points: 0,
    points_required: 0,
    current_rank: 0,
    current_level: 0,
    progress: 0,
    points_for_academics: 0,
    points_for_sport: 0,
    points_for_culture: 0,
    points_for_other: 0,
  });

  const [rankings, setRankings] = useState<Rank[]>([]);

  useEffect(() => {
    fetchStudentRanking({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.studentRanking) {
            setStudentRanking({
              ...response.data.studentRanking,
              progress: Math.round(
                (response.data.studentRanking.points /
                  response.data.studentRanking.points_required) *
                  100
              ),
            });
          }
        }
      })
      .catch(console.log);

    fetchRankList({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.rankings) {
            setRankings(response.data.rankings);
          }
        }
      })
      .catch(console.log);
  }, [token]);

  const handleSortRanking = (a: Rank, b: Rank) => {
    return (
      (a.current_rank as unknown as number) -
      (b.current_rank as unknown as number)
    );
  };

  return (
    <MainLayout enabledOnly>
      <div className="mb-16 flex flex-col md:flex-row justify-between items-start">
        <span className="font-semibold text-6xl text-d-green dark:text-l-green">
          #{studentRanking.current_rank}
        </span>

        <div className="w-full mt-3 md:ml-32 flex-grow">
          <div className="flex flex-col justify-center items-center">
            <ProgressBar progress={studentRanking.progress} size="large" />

            <div className="w-full mt-5 px-5 flex justify-between items-start">
              <span className="text-left text-2xl text-black dark:text-white">
                {studentRanking.current_level}

                <div className="m-0 p-0 font-light text-xs text-gray-600 dark:text-white">
                  huidige valk
                </div>
              </span>

              <span className="text-2xl text-gray-600 dark:text-white">
                {studentRanking.points} / {studentRanking.points_required}
              </span>

              <span className="text-right text-2xl text-black dark:text-white">
                {studentRanking.current_level + 1}

                <div className="m-0 p-0 font-light text-xs text-gray-600 dark:text-white">
                  volgende valk
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-16 flex flex-wrap justify-between items-center gap-y-8">
        <div className="w-full md:max-w-[300px] py-6 flex flex-col justify-center items-center bg-white rounded-lg shadow-lg dark:bg-l-gray">
          <span className="mb-6 font-bold text-2xl text-black dark:text-white">
            {studentRanking.points_for_academics} punte
          </span>
          <span className="font-semibold text-base text-gray-200 dark:text-white">
            Akademiese Deelname
          </span>
        </div>

        <div className="w-full md:max-w-[300px] py-6 flex flex-col justify-center items-center bg-white rounded-lg shadow-lg dark:bg-l-gray">
          <span className="mb-6 font-bold text-2xl text-black dark:text-white">
            {studentRanking.points_for_sport} punte
          </span>
          <span className="font-semibold text-base text-gray-200 dark:text-white">
            Sport Deelname
          </span>
        </div>

        <div className="w-full md:max-w-[300px] py-6 flex flex-col justify-center items-center bg-white rounded-lg shadow-lg dark:bg-l-gray">
          <span className="mb-6 font-bold text-2xl text-black dark:text-white">
            {studentRanking.points_for_culture} punte
          </span>
          <span className="font-semibold text-base text-gray-200 dark:text-white">
            Kulturele Deelname
          </span>
        </div>

        <div className="w-full md:max-w-[300px] py-6 flex flex-col justify-center items-center bg-white rounded-lg shadow-lg dark:bg-l-gray">
          <span className="mb-6 font-bold text-2xl text-black dark:text-white">
            {studentRanking.points_for_other} punte
          </span>
          <span className="font-semibold text-base text-gray-200 dark:text-white">
            Ander Deelname
          </span>
        </div>
      </div>

      <div className="w-full py-6 px-8 bg-white rounded-lg shadow-xl dark:bg-l-gray">
        <h2 className="mb-11 font-semibold text-2xl text-black dark:text-white">
          Ranglys
        </h2>

        {rankings.sort(handleSortRanking).map((rank: Rank) => {
          return (
            <div className="mb-5 px-8 py-4 flex justify-between items-center bg-gray-500 rounded-xl dark:bg-d-gray dark:text-white">
              <div>
                <span className="mr-4 py-3 px-2 border-2 border-white bg-d-green font-extrabold text-xl text-white text-center rounded-full shadow-lg uppercase dark:bg-l-green">
                  {rank.student.f_name[0]}
                  {rank.student.l_name[0]}
                </span>
                <span className="mb-2 font-bold text-base">
                  {rank.student.f_name} {rank.student.l_name}
                </span>
              </div>

              <div className="flex justify-center items-center">
                <span className="mr-10">{rank.points} punte</span>
                <span className="font-semibold text-4xl text-d-green dark:text-l-green">
                  #{rank.current_rank}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </MainLayout>
  );
};

export default RankingPage;
