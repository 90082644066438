import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { AssessmentIcon } from "../components/Icons";

interface AssessmentCardProps {
  assessment: Assessment;
}

interface Assessment {
  assessment_id: number;
  assessment_title: string;
  grade: number;
  module: number;
  subject_id: number;
  assessment_url: string;
  o_datetime: string;
  c_datetime: string;
  date_created: string;
  subject: string;
  completed: string;
}

const AssessmentCard: FC<AssessmentCardProps> = ({ assessment }) => {
  const navigate = useNavigate();

  const getDate = (timeString: string) => {
    const listOfMonths = [
      "Januarie",
      "Februarie",
      "Maart",
      "April",
      "Mei",
      "Junie",
      "Julie",
      "Augustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    const currentDate = new Date(timeString);

    return (
      currentDate.getDate() +
      " " +
      listOfMonths[currentDate.getMonth()] +
      " " +
      currentDate.getFullYear()
    );
  };

  const getTime = (timeString: string) => {
    const currentTime = new Date(timeString);

    return (
      (currentTime.getHours() < 10
        ? "0" + currentTime.getHours()
        : currentTime.getHours()) +
      ":" +
      (currentTime.getMinutes() < 10
        ? "0" + currentTime.getMinutes()
        : currentTime.getMinutes())
    );
  };

  const getTimeDuration = (oTimeString: string, cTimeString: string) => {
    const oDate = new Date(oTimeString);
    const cDate = new Date(cTimeString);

    const durationHours = Math.floor(
      (cDate.valueOf() - oDate.valueOf()) / 3600000
    );

    const durationMinutes = Math.round(
      Math.round((cDate.valueOf() - oDate.valueOf()) % 3600000) / 60000
    );

    const hourWord = durationHours === 1 ? " uur " : " ure ";

    return durationHours + hourWord + durationMinutes + " min";
  };

  const isComplete = (timeString: string) => {
    return new Date().valueOf() > new Date(timeString).valueOf();
  };

  const handleAssessmentClick = (assessment: Assessment) => {
    localStorage.setItem("active-assessment", JSON.stringify(assessment));
    navigate("/no/testing");
  };

  return (
    <div className="mb-8 mx-auto flex flex-col lg:flex-row justify-between items-center">
      <span className="lg:mr-12 font-medium text-xl text-black dark:text-white">
        {getDate(assessment.o_datetime)} - {getTime(assessment.o_datetime)}
      </span>

      <div className="w-full lg:w-auto lg:flex-grow px-10 py-8 flex flex-col lg:flex-row justify-between items-center bg-white rounded-xl shadow-xl dark:bg-l-gray dark:text-white">
        <div className="mb-8 lg:mb-0 flex justify-center items-center">
          <AssessmentIcon className="hidden lg:inline fill-black dark:fill-white" />

          <span className="w-[350px] lg:ml-14 text-2xl text-center lg:text-left">
            {assessment.assessment_title}
          </span>
        </div>

        {isComplete(assessment.o_datetime) ? (
          assessment.completed === "true" ? (
            <span className="dark:text-white">Ingedien!</span>
          ) : isComplete(assessment.c_datetime) ? (
            <span className="dark:text-white">Nie Ingedien Nie!</span>
          ) : (
            <span
              className="text-d-green cursor-pointer hover:underline dark:text-l-green"
              onClick={() => handleAssessmentClick(assessment)}
            >
              Begin Assessering
            </span>
          )
        ) : null}
      </div>
    </div>
  );
};

export default AssessmentCard;
