import axios from "./axios";

interface FetchVideosRequest {
  token: string;
}

interface FetchVideosResponse {
  data: {
    status: "success" | "error";
    videos?: {
      video_id: number;
      video_title: string;
      video_subtitle: string;
      vimeo_stream_link: string;
      date_created: string;
      completed: string;
    }[];
    errorMessage?: string;
  };
}

export async function fetchVideos(
  data: FetchVideosRequest
): Promise<FetchVideosResponse> {
  return axios.post("nf/fetch-videos.php", data);
}

interface CompleteVideoRequest {
  token: string;
  video_id: number;
}

interface CompleteVideoResponse {
  data: {
    status: "success" | "error";
    errorMessage?: string;
  };
}

export async function completeVideo(
  data: CompleteVideoRequest
): Promise<CompleteVideoResponse> {
  return axios.post("nf/complete-video.php", data);
}
