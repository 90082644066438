import { FC } from "react";

interface ProgressBarProps {
  progress: number;
  size: "small" | "large";
}

const ProgressBar: FC<ProgressBarProps> = ({ progress, size }) => {
  return (
    <div
      className="w-full bg-transparent border border-d-green rounded-full dark:border-l-green"
      style={{ height: size === "small" ? "25px" : "40px" }}
    >
      <div
        className="h-full m-0 p-0 bg-d-green rounded-l-full dark:bg-l-green"
        style={{ width: progress + "%" }}
      />
    </div>
  );
};

export default ProgressBar;
