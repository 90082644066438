import { FC, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoadingPage from "./pages/LoadingPage";

import WelcomePage from "./pages/WelcomePage";
import RegisterStudentPage from "./pages/RegisterStudentPage";
import AuthenticateSIDPage from "./pages/AuthenticateSIDPage";
import AuthenticateOTPPage from "./pages/AuthenticateOTPPage";

import DashboardPage from "./pages/DashboardPage";

import StudentDashboardPage from "./pages/StudentDashboardPage";

import LibraryPage from "./pages/LibraryPage";
import ReadingPage from "./pages/ReadingPage";
import GalleryPage from "./pages/GalleryPage";
import ViewingPage from "./pages/ViewingPage";
import ClassesPage from "./pages/ClassesPage";
import StudiesPage from "./pages/StudiesPage";
import TestingPage from "./pages/TestingPage";

import ExerciseGalleryPage from "./pages/ExerciseGalleryPage";
import ExerciseViewingPage from "./pages/ExerciseViewingPage";
import ExerciseRewardsPage from "./pages/ExerciseRewardsPage";

import BookstoreOnlinePage from "./pages/BookstoreOnlinePage";
import BookstoreOrdersPage from "./pages/BookstoreOrdersPage";
import BookstoreThanksPage from "./pages/BookstoreThanksPage";

import RankingPage from "./pages/RankingPage";
import ProfilePage from "./pages/ProfilePage";

interface ApplicationProps {}

const Application: FC<ApplicationProps> = () => {
  useEffect(() => {
    const handleContextmenu = (e: { preventDefault: () => void }) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<LoadingPage />} />

        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/register-student" element={<RegisterStudentPage />} />
        <Route path="/authenticate-sid" element={<AuthenticateSIDPage />} />
        <Route path="/authenticate-otp" element={<AuthenticateOTPPage />} />

        <Route path="/dashboard" element={<DashboardPage />} />

        <Route path="/student-dashboard" element={<StudentDashboardPage />} />

        <Route path="/no/library" element={<LibraryPage />} />
        <Route path="/no/reading" element={<ReadingPage />} />
        <Route path="/no/gallery" element={<GalleryPage />} />
        <Route path="/no/viewing" element={<ViewingPage />} />
        <Route path="/no/classes" element={<ClassesPage />} />
        <Route path="/no/studies" element={<StudiesPage />} />
        <Route path="/no/testing" element={<TestingPage />} />

        <Route path="/nf/gallery" element={<ExerciseGalleryPage />} />
        <Route path="/nf/viewing" element={<ExerciseViewingPage />} />
        <Route path="/nf/rewards" element={<ExerciseRewardsPage />} />

        <Route path="/nb/online" element={<BookstoreOnlinePage />} />
        <Route path="/nb/orders" element={<BookstoreOrdersPage />} />
        <Route path="/nb/thanks" element={<BookstoreThanksPage />} />

        <Route path="/ranking" element={<RankingPage />} />
        <Route path="/profile" element={<ProfilePage />} />
      </Routes>

      <ToastContainer position="top-right" newestOnTop={true} />
    </>
  );
};

export default Application;
