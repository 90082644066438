import { FC, useState, useEffect, useContext } from "react";

import StudentContext from "../context/StudentContext";

import { fetchSubjects } from "../api/dataEndpoints";

interface Subject {
  subject_id: string;
  subject_name: string;
}

interface FilterSubjectProps {
  setFilterSubject: (subject: string) => void;
}

const FilterSubject: FC<FilterSubjectProps> = ({ setFilterSubject }) => {
  const { token } = useContext(StudentContext);

  const [subjects, setSubjects] = useState<Subject[]>([]);

  useEffect(() => {
    fetchSubjects({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.subjects) {
            setSubjects(response.data.subjects);
          }
        }
      })
      .catch(console.log);
  }, [token]);

  return (
    <select
      className="cc-dropdown-arrow w-full max-w-[400px] h-[50px] lg:mr-4 pl-4 text-base bg-white cursor-pointer rounded-lg shadow-xl dark:bg-d-black dark:text-white"
      onChange={(e) => setFilterSubject(e.target.value)}
    >
      {subjects.map((subject) => {
        return (
          <option key={subject.subject_id} value={subject.subject_id}>
            {subject.subject_name}
          </option>
        );
      })}
    </select>
  );
};

export default FilterSubject;
