import { FC } from "react";

import { SearchIcon, ClearIcon } from "../components/Icons";

interface SearchProps {
  placeholder: string;
  search: string;
  setSearch: (newSearch: string) => void;
}

const Search: FC<SearchProps> = ({ placeholder, search, setSearch }) => {
  return (
    <div className="w-full max-w-[500px] pr-4 flex justify-between items-center bg-white rounded-lg shadow-xl dark:bg-d-black">
      <input
        type="text"
        placeholder={placeholder}
        className="w-full max-w-[400px] h-[50px] mr-4 pl-4 rounded-lg dark:bg-d-black dark:text-white"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />

      {search.length > 0 ? (
        <span
          className="cursor-pointer hover:animate-pulse"
          onClick={() => setSearch("")}
        >
          <ClearIcon className="fill-black dark:fill-white" />
        </span>
      ) : (
        <SearchIcon className="fill-black dark:fill-white" />
      )}
    </div>
  );
};

export default Search;
