import axios from "axios";

export default axios.create({
  withCredentials: false,
  // baseURL: "http://localhost/nukleus_server/api/",
  baseURL: "https://nukleusontwerp.co.za/api/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
