import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { BackIcon } from "../components/Icons";

interface ViewingPageProps {}

const ViewingPage: FC<ViewingPageProps> = () => {
  const navigate = useNavigate();

  const video = JSON.parse(localStorage.getItem("active-video") as string);

  return (
    <div className="w-screen h-screen overflow-hidden dark:bg-l-gray">
      <div className="h-full -mt-20 flex justify-start items-stretch">
        <div className="w-20 h-full pt-20 bg-white dark:bg-l-gray">
          <div className="pt-8 flex justify-center items-center">
            <span
              className="cursor-pointer hover:animate-pulse"
              onClick={() => navigate(-1)}
            >
              <BackIcon className="fill-black dark:fill-white" />
            </span>
          </div>
        </div>

        <div className="w-full h-full mb-20 pt-32 pb-12 lg:px-12 bg-gray-500 overflow-scroll rounded-bl-3xl dark:bg-d-gray">
          <div className="w-full h-full px-4 md:px-16 flex justify-center items-center">
            <iframe
              src={`https://player.vimeo.com/video/${video.vimeo_stream_link}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              className="w-full h-full rounded-xl"
              title="Nukleus Opname"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="relative w-full h-20 bg-white dark:bg-l-gray dark:text-white">
        <div className="h-full pl-20 pr-10 flex flex-col lg:flex-row justify-between items-center">
          <span className="text-center">{video.video_title}</span>
          <span className="text-center">
            {video.subject} - Module {video.module}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ViewingPage;
