import axios from "./axios";

interface FetchStudentRequest {
  token: string;
}

interface FetchStudentResponse {
  data: {
    status: "success" | "error";
    student?: {
      student_id: number;
      f_name: string;
      l_name: string;
      id_number: string;
      grade: number;
      subscription: string;
      subscription_token: string;
      subscription_amount: number;
      authentication_token: string;
      authentication_otp: string;
      parent_full_name: string;
      parent_email: string;
      parent_phone_number: string;
      date_created: string;
    };
    errorMessage?: string;
  };
}

export async function fetchStudent(
  data: FetchStudentRequest
): Promise<FetchStudentResponse> {
  return axios.post("student/fetch-student.php", data);
}

interface FetchStudentRankingRequest {
  token: string;
}

interface FetchStudentRankingResponse {
  data: {
    status: "success" | "error";
    studentRanking?: {
      ranking_id: number;
      student_id: number;
      points: number;
      points_for_academics: number;
      points_for_sport: number;
      points_for_culture: number;
      points_for_other: number;
      current_rank: number;
      current_level: number;
      points_required: number;
    };
    errorMessage?: string;
  };
}

export async function fetchStudentRanking(
  data: FetchStudentRankingRequest
): Promise<FetchStudentRankingResponse> {
  return axios.post("student/fetch-student-ranking.php", data);
}

interface FetchStudentBadgesRequest {
  token: string;
}

interface FetchStudentBadgesResponse {
  data: {
    status: "success" | "error";
    lastAchievement: {
      achievement_badge_url: string;
    };
    challengeBadges: {
      challenge_badge_url: string;
    }[];
    errorMessage?: string;
  };
}

export async function fetchStudentBadges(
  data: FetchStudentBadgesRequest
): Promise<FetchStudentBadgesResponse> {
  return axios.post("student/fetch-student-badges.php", data);
}

interface FetchStudentScheduleRequest {
  token: string;
}

interface FetchStudentScheduleResponse {
  data: {
    status: "success" | "error";
    classes?: {
      class_id: number;
      class_title: string;
      grade: number;
      module: number;
      subject_id: number;
      teacher_id: number;
      zoom_link: string;
      zoom_meeting_id: string;
      zoom_meeting_password: string;
      o_datetime: string;
      c_datetime: string;
      date_created: string;
      teacher: string;
      subject: string;
    }[];
    assessments?: {
      assessment_id: number;
      assessment_title: string;
      grade: number;
      module: number;
      subject_id: number;
      assessment_url: string;
      o_datetime: string;
      c_datetime: string;
      date_created: string;
      subject: string;
    }[];
    errorMessage?: string;
  };
}

export async function fetchStudentSchedule(
  data: FetchStudentScheduleRequest
): Promise<FetchStudentScheduleResponse> {
  return axios.post("student/fetch-student-schedule.php", data);
}

interface FetchStudentAchievementsRequest {
  token: string;
}

interface FetchStudentAchievementsResponse {
  data: {
    status: "success" | "error";
    achievements: {
      achievement_title: string;
      achievement_badge_url: string;
    }[];
    errorMessage?: string;
  };
}

export async function fetchStudentAchievements(
  data: FetchStudentAchievementsRequest
): Promise<FetchStudentAchievementsResponse> {
  return axios.post("student/fetch-student-achievements.php", data);
}
