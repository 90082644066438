import { FC } from "react";

interface FormHeadingProps {
  headingText: string;
}

const FormHeading: FC<FormHeadingProps> = ({ headingText }) => {
  return (
    <h2 className="w-full mb-14 text-center font-semibold lg:text-left text-3xl text-h-black dark:text-white">
      {headingText}
    </h2>
  );
};

export default FormHeading;
