import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";

import StudentContext from "../context/StudentContext";

import { completeVideo } from "../api/nfExerciseEndpoints";

import { BackIcon, NextIcon } from "../components/Icons";
import { toast } from "react-toastify";

interface ExercisePageProps {}

const ExercisePage: FC<ExercisePageProps> = () => {
  const navigate = useNavigate();

  const { token, assignStudentPoints } = useContext(StudentContext);

  const video = JSON.parse(localStorage.getItem("active-video") as string);

  const handleCompleteExercise = () => {
    completeVideo({ token: token, video_id: video.video_id })
      .then((response) => {
        if (response.data.status === "success") {
          toast.success("Wel gedaan! Oefening voltooi.");
          navigate("/nf/gallery");

          if (video.completed === "true") {
            assignStudentPoints(
              20,
              video.video_title + " (oefening herhaal)",
              "sport"
            );
          } else {
            assignStudentPoints(
              100,
              video.video_title + " (oefening voltooi)",
              "sport"
            );
          }
        } else {
          toast.error("Oefening voltooi fout. Probeer weer.");
        }
      })
      .catch(console.log);
  };

  return (
    <div className="w-screen h-screen overflow-hidden dark:bg-l-gray">
      <div className="h-full -mt-20 flex justify-start items-stretch">
        <div className="w-20 h-full pt-20 bg-white dark:bg-l-gray">
          <div className="pt-8 flex justify-center items-center">
            <span
              className="cursor-pointer hover:animate-pulse"
              onClick={() => navigate(-1)}
            >
              <BackIcon className="fill-black dark:fill-white" />
            </span>
          </div>
        </div>

        <div className="w-full h-full mb-20 pt-32 pb-12 lg:px-12 bg-gray-500 overflow-scroll rounded-bl-3xl dark:bg-d-gray">
          <div className="w-full h-full px-4 md:px-16 flex justify-center items-center">
            <iframe
              src={`https://player.vimeo.com/video/${video.vimeo_stream_link}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              className="w-full h-full rounded-xl"
              title="Nukleus Opname"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="relative w-full h-20 bg-white dark:bg-l-gray dark:text-white">
        <div className="h-full md:pl-20 md:pr-10 flex flex-col md:flex-row justify-center md:justify-between items-center space-y-2 md:space-y-0">
          <div className="space-x-4">
            <span>{video.video_title}</span>
            <span>{video.video_subtitle}</span>
          </div>

          <span
            className="flex justify-center items-center text-d-green cursor-pointer hover:underline dark:text-l-green"
            onClick={handleCompleteExercise}
          >
            {video.completed === "true"
              ? "Oefening Herhaal"
              : "Oefening Voltooi"}

            <NextIcon className="ml-2 fill-d-green dark:fill-l-green" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ExercisePage;
