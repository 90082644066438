import { FC, useState, useEffect, useContext } from "react";

import StudentContext from "../context/StudentContext";

import { fetchVideos } from "../api/noVideoEndpoints";

import MainLayout from "../components/MainLayout";
import FilterSubject from "../components/FilterSubject";
import FilterModule from "../components/FilterModule";
import Search from "../components/Search";
import VideoCard from "../components/VideoCard";

interface Video {
  video_id: number;
  video_title: string;
  grade: number;
  module: number;
  subject_id: number;
  vimeo_stream_link: string;
  vimeo_download_link: string;
  date_created: string;
  subject: string;
}

const GalleryPage: FC = () => {
  const { token } = useContext(StudentContext);

  const [videos, setVideos] = useState<Video[]>([]);

  const [filterSubject, setFilterSubject] = useState("4");
  const [filterModule, setFilterModule] = useState("1");
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchVideos({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.videos) {
            setVideos(response.data.videos);
          }
        }
      })
      .catch(console.log);
  }, [token]);

  const handleFilterVideos = (video: Video) => {
    if (search.length > 0) {
      return video.video_title.toLowerCase().includes(search.toLowerCase());
    }

    return (
      video.subject_id.toString() === filterSubject &&
      video.module.toString() === filterModule
    );
  };

  return (
    <MainLayout enabledOnly>
      <div className="mb-20">
        <div className="flex flex-col lg:flex-row justify-between items-center space-y-8 lg:space-y-0">
          <div className="w-full flex flex-col lg:flex-row justify-center lg:justify-start items-center lg:items-start space-y-5 lg:space-y-0">
            <FilterSubject setFilterSubject={setFilterSubject} />
            <FilterModule setFilterModule={setFilterModule} />
          </div>

          <Search
            placeholder="Soek Opname"
            search={search}
            setSearch={setSearch}
          />
        </div>
      </div>

      <div className="flex flex-wrap justify-center items-center">
        {videos.filter(handleFilterVideos).map((video) => {
          return <VideoCard key={video.video_id} video={video} />;
        })}
      </div>
    </MainLayout>
  );
};

export default GalleryPage;
