import { FC } from "react";

interface FormDropdownProps {
  label: string;
  inputName: string;
  options: { name: string; value: string }[];
  formRegister: any;
}

const FormDropdown: FC<FormDropdownProps> = ({
  label,
  inputName,
  options,
  formRegister,
}) => {
  return (
    <div className="w-full mb-12 flex flex-col justify-start items-start">
      <label className="mb-2 font-medium text-xs text-h-black uppercase dark:text-white">
        {label}
      </label>
      <select
        name={inputName}
        className="cc-dropdown-arrow w-full max-w-[400px] h-[50px] pl-4 text-base bg-white border border-gray-300 rounded-lg shadow-lg dark:bg-d-black"
        {...formRegister(inputName, { required: true })}
      >
        {options.map((option) => {
          return (
            <option key={option.name} value={option.value}>
              {option.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default FormDropdown;
