import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";

import ThemeContext from "../context/ThemeContext";

import AuthenticationLayout from "../components/AuthenticationLayout";
import MainHeading from "../components/MainHeading";
import Button from "../components/Button";

interface WelcomePageProps {}

const WelcomePage: FC<WelcomePageProps> = () => {
  const navigate = useNavigate();

  const { theme } = useContext(ThemeContext);

  return (
    <AuthenticationLayout>
      <img
        src={
          theme === "light"
            ? "http://nukleusontwerp.co.za/assets/nukleus-logo-dark.png"
            : "http://nukleusontwerp.co.za/assets/nukleus-logo.png"
        }
        alt="Nukleus Onderwys Logo"
        width={175}
        height={285}
      />

      <div className="mt-16 mb-8 ">
        <MainHeading plainText="Welkom by" themeText="Nukleus Onderwys" />
      </div>

      <p className="max-w-[700px] mb-20 text-center text-base text-gray-100 dark:text-white">
        Nukleus Onderwys het seker gemaak dat ons bekende kwaliteit,
        waarheidgedrewe, Afrikaanse lesmateriaal pragtig in 'n splinternuwe
        volledige toepassing saamgevat is. Wat leer en lees weer maklik en
        lekker maak!
      </p>

      <div className="flex flex-col md:flex-row justify-center items-center md:space-x-8 space-y-5 md:space-y-0">
        <Button
          type="solid"
          buttonText="Registreer ‘n Nuwe Leerder"
          onClick={() => navigate("/register-student")}
        />

        <Button
          type="outlined"
          buttonText="Teken In met Bestaande Leerder"
          onClick={() => navigate("/authenticate-sid")}
        />
      </div>
    </AuthenticationLayout>
  );
};

export default WelcomePage;
