import { FC } from "react";

import { ClassIcon } from "../components/Icons";

interface ClassCardProps {
  classData: {
    class_id: number;
    class_title: string;
    grade: number;
    module: number;
    subject_id: number;
    teacher_id: number;
    zoom_link: string;
    zoom_meeting_id: string;
    zoom_meeting_password: string;
    o_datetime: string;
    c_datetime: string;
    date_created: string;
    teacher: string;
    subject: string;
  };
}

const ClassCard: FC<ClassCardProps> = ({ classData }) => {
  const getTime = (timeString: string) => {
    const currentTime = new Date(timeString);

    return (
      (currentTime.getHours() < 10
        ? "0" + currentTime.getHours()
        : currentTime.getHours()) +
      ":" +
      (currentTime.getMinutes() < 10
        ? "0" + currentTime.getMinutes()
        : currentTime.getMinutes())
    );
  };

  const isComplete = (timeString: string) => {
    return new Date().valueOf() > new Date(timeString).valueOf();
  };

  return (
    <div className="mb-8 mx-auto flex flex-col lg:flex-row justify-between items-center">
      <span className="lg:mr-10 font-medium text-xl text-black dark:text-white">
        {getTime(classData.o_datetime)} - {getTime(classData.c_datetime)}
      </span>

      <div className="lg:flex-grow w-full lg:w-auto px-8 py-4 flex flex-col lg:flex-row justify-between items-center bg-white rounded-xl shadow-xl dark:bg-l-gray">
        <div className="flex justify-center items-center dark:text-white">
          <ClassIcon className="hidden lg:inline fill-black dark:fill-white" />

          <span className="w-[250px] mb-5 lg:mb-0 lg:ml-14 text-2xl text-center lg:text-left">
            {classData.class_title}
          </span>
          {/* <span>{classData.teacher}</span> */}
        </div>

        {isComplete(classData.o_datetime) ? (
          isComplete(classData.c_datetime) ? (
            <span className="dark:text-white">Klaar!</span>
          ) : (
            <a
              className="text-d-green cursor-pointer hover:underline dark:text-l-green"
              href={classData.zoom_link}
              target="_blank"
              rel="noreferrer"
            >
              Begin Klas
            </a>
          )
        ) : null}
      </div>
    </div>
  );
};

export default ClassCard;
