import { FC, useContext, useEffect, useState } from "react";

import StudentContext from "../context/StudentContext";

import { fetchAssessments } from "../api/noAssessmentEndpoints";

import AssessmentCard from "./AssessmentCard";
import { EmptyIcon } from "../components/Icons";

interface AssessmentsTabProps {}

interface Assessment {
  assessment_id: number;
  assessment_title: string;
  grade: number;
  module: number;
  subject_id: number;
  assessment_url: string;
  o_datetime: string;
  c_datetime: string;
  date_created: string;
  subject: string;
  completed: string;
}

const AssessmentsTab: FC<AssessmentsTabProps> = () => {
  const { token } = useContext(StudentContext);

  const [assessments, setAssessments] = useState<Assessment[]>([]);

  useEffect(() => {
    fetchAssessments({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.assessments) {
            setAssessments(response.data.assessments);
          }
        }
      })
      .catch(console.log);
  }, [token]);

  return assessments.length > 0 ? (
    <div className="max-w-[1250px] mx-auto">
      {assessments.map((assessment) => {
        return (
          <AssessmentCard
            key={assessment.assessment_id}
            assessment={assessment}
          />
        );
      })}
    </div>
  ) : (
    <div className="w-full mt-40 flex flex-col justify-center items-center">
      <EmptyIcon className="fill-d-green dark:fill-l-green" />

      <span className="mt-10 text-4xl text-gray-100 dark:text-white">
        Geen assesserings geskeduleer vir vandag nie.
      </span>
    </div>
  );
};

export default AssessmentsTab;
