import { FC, useState, useEffect, useContext } from "react";

import StudentContext from "../context/StudentContext";

import { fetchClasses } from "../api/noClassEndpoints";

import MainLayout from "../components/MainLayout";
import MainHeading from "../components/MainHeading";
import DateTime from "../components/DateTime";
import ClassCard from "../components/ClassCard";
import { EmptyIcon } from "../components/Icons";

interface Class {
  class_id: number;
  class_title: string;
  grade: number;
  module: number;
  subject_id: number;
  teacher_id: number;
  zoom_link: string;
  zoom_meeting_id: string;
  zoom_meeting_password: string;
  o_datetime: string;
  c_datetime: string;
  date_created: string;
  teacher: string;
  subject: string;
}

const ClassesPage: FC = () => {
  const { token } = useContext(StudentContext);

  const [classes, setClasses] = useState<Class[]>([]);

  useEffect(() => {
    fetchClasses({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.classes) {
            setClasses(response.data.classes);
          }
        }
      })
      .catch(console.log);
  }, [token]);

  return (
    <MainLayout enabledOnly>
      {classes.length > 0 ? (
        <div>
          <div className="mb-20 flex justify-between items-center">
            <MainHeading plainText="Klasse vir Vandag" themeText="" />

            <div className="hidden md:block">
              <DateTime />
            </div>
          </div>

          <div className="max-w-[1100px] mx-auto">
            {classes.map((c) => {
              return <ClassCard key={c.class_id} classData={c} />;
            })}
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex flex-col justify-center items-center">
          <EmptyIcon className="fill-d-green dark:fill-l-green" />

          <span className="mt-10 text-4xl text-gray-100 dark:text-white">
            Geen klasse geskeduleer vir vandag nie.
          </span>
        </div>
      )}
    </MainLayout>
  );
};

export default ClassesPage;
