import { FC } from "react";

interface TabToggleProps {
  activeTab: string;
  updateTab: (tab: string) => void;
}

const TabToggle: FC<TabToggleProps> = ({ activeTab, updateTab }) => {
  return (
    <select
      className="shadow-lg cc-dropdown-arrow w-[275px] h-[50px] mr-4 pl-6 text-base bg-white cursor-pointer border border-[#EEE] dark:border-0 dark:bg-d-black dark:text-white rounded-3xl"
      onChange={(e) => {
        const value = e.target.value;
        if (value === "assessments" || value === "assessments-completed") {
          updateTab(e.target.value);
        }
      }}
    >
      <option value="assessments" selected={activeTab === "assessments"}>
        Assesserings
      </option>

      <option
        value="assessments-completed"
        selected={activeTab === "assessments-completed"}
      >
        Ingediende Assesserings
      </option>
    </select>
  );
};

export default TabToggle;
