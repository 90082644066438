import { FC, useContext } from "react";

import StudentContext from "../context/StudentContext";

import MainLayout from "../components/MainLayout";
import MainHeading from "../components/MainHeading";
import DateTime from "../components/DateTime";
import SubscriptionBanner from "../components/SubscriptionBanner";
import ProfileCard from "../components/ProfileCard";
import StudentSchedule from "../components/StudentSchedule";
import NotificationAlerts from "../components/NotificationAlerts";

interface StudentDashboardProps {}

const StudentDashboard: FC<StudentDashboardProps> = () => {
  const { student } = useContext(StudentContext);

  return (
    <MainLayout>
      <div className="mb-10 flex justify-between items-center">
        <MainHeading
          plainText="Welkom terug,"
          themeText={`${student.f_name}!`}
        />

        <div className="hidden lg:inline">
          <DateTime />
        </div>
      </div>

      {student.subscription === "disabled" ? <SubscriptionBanner /> : null}

      <div className="mt-8 flex flex-col lg:flex-row justify-start items-center md:items-start space-y-8 lg:space-y-0 lg:space-x-8">
        <ProfileCard />

        <div className="w-full lg:w-auto flex-grow">
          <NotificationAlerts />
          <StudentSchedule />
        </div>
      </div>
    </MainLayout>
  );
};

export default StudentDashboard;
