import { FC, useState, useEffect, useContext } from "react";

import StudentContext from "../context/StudentContext";

import { fetchStudentAchievements } from "../api/studentEndpoints";

interface Achievement {
  achievement_title: string;
  achievement_badge_url: string;
}

const StudentAchievements: FC = () => {
  const { token } = useContext(StudentContext);

  const [achievements, setAchievements] = useState<Achievement[]>([]);

  useEffect(() => {
    fetchStudentAchievements({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.achievements) {
            setAchievements(response.data.achievements);
          }
        }
      })
      .catch(console.log);
  }, [token]);

  return (
    <div className="w-full py-6 px-8 bg-white rounded-lg shadow-xl dark:bg-l-gray">
      <h2 className="mb-11 font-semibold text-2xl text-black dark:text-white">
        Spesiale Toekennings
      </h2>

      {achievements.length > 0 ? (
        <div className="flex flex-wrap justify-between items-center">
          {achievements.map((achievement) => {
            return (
              <div className="mb-10 w-[200px] py-7 flex flex-col justify-center items-center bg-gray-500 rounded-lg dark:bg-d-gray">
                <img
                  src={achievement.achievement_badge_url}
                  alt="Achievement 1"
                  width={80}
                  height={80}
                />

                <span className="mt-12 text-xs text-gray-300 dark:text-white">
                  {achievement.achievement_title}
                </span>
              </div>
            );
          })}
        </div>
      ) : (
        <p className="my-[65px] lg:my-[78px] text-center dark:text-white">
          Jy het nog nie enige spesiale toekennings nie.
        </p>
      )}
    </div>
  );
};

export default StudentAchievements;
