import { FC, useState, useEffect, useContext } from "react";

import StudentContext from "../context/StudentContext";

import { fetchBooks } from "../api/noBookEndpoints";

import MainLayout from "../components/MainLayout";
import FilterSubject from "../components/FilterSubject";
import FilterModule from "../components/FilterModule";
import Search from "../components/Search";
import BookCard from "../components/BookCard";

interface Book {
  book_id: string;
  book_title: string;
  grade: string;
  module: string;
  subject_id: string;
  cover_img_url: string;
  pdf_url: string;
  pdf_password: string;
  chapters: string;
  page_count: string;
}

const LibraryPage: FC = () => {
  const { token } = useContext(StudentContext);

  const [books, setBooks] = useState<Book[]>([]);

  const [filterSubject, setFilterSubject] = useState("4");
  const [filterModule, setFilterModule] = useState("1");
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchBooks({ token: token })
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.books) {
            setBooks(response.data.books);
          }
        }
      })
      .catch(console.log);
  }, [token]);

  const handleFilterBooks = (book: Book) => {
    if (search.length > 0) {
      return book.book_title.toLowerCase().includes(search.toLowerCase());
    }

    return (
      book.subject_id.toString() === filterSubject &&
      book.module.toString() === filterModule
    );
  };

  return (
    <MainLayout>
      <div className="mb-20">
        <div className="flex flex-col lg:flex-row justify-between items-center space-y-8 lg:space-y-0">
          <div className="w-full flex flex-col lg:flex-row justify-center lg:justify-start items-center lg:items-start space-y-5 lg:space-y-0">
            <FilterSubject setFilterSubject={setFilterSubject} />
            <FilterModule setFilterModule={setFilterModule} />
          </div>

          <Search
            placeholder="Soek Opname"
            search={search}
            setSearch={setSearch}
          />
        </div>
      </div>

      <div className="flex flex-wrap justify-center items-center">
        {books.filter(handleFilterBooks).map((book) => {
          return <BookCard key={book.book_id} book={book} />;
        })}

        {books.filter(handleFilterBooks).length === 0 ? (
          <p className="w-full mt-10 text-black dark:text-white text-center">
            Daar is geen boeke vir hierdie vak aan jou profiel gekoppel nie. Kyk
            gerus na die aanlyn boekwinkel om hierdie boeke te koop.
          </p>
        ) : null}
      </div>
    </MainLayout>
  );
};

export default LibraryPage;
