/* eslint-disable @typescript-eslint/no-redeclare */
import { FC, ReactNode, createContext, useState } from "react";
import { toast } from "react-toastify";

import { assignPoints } from "../api/rankingEndpoints";

interface StudentContext {
  token: string;
  setToken: (token: string) => void;
  student: {
    student_id: number;
    f_name: string;
    l_name: string;
    id_number: string;
    grade: number;
    subscription: string;
    subscription_token: string;
    subscription_amount: number;
    authentication_token: string;
    authentication_otp: string;
    parent_full_name: string;
    parent_email: string;
    parent_phone_number: string;
    date_created: string;
  };
  setStudent: (student: any) => void;
  assignStudentPoints: (
    points: number,
    description: string,
    category: string
  ) => void;
}

interface StudentContextProps {
  children: ReactNode;
}

const StudentContextDefaultValues: StudentContext = {
  token: "",
  setToken: (token: string) => {},
  student: {
    student_id: 0,
    f_name: "",
    l_name: "",
    id_number: "",
    grade: 0,
    subscription: "",
    subscription_token: "",
    subscription_amount: 0,
    authentication_token: "",
    authentication_otp: "",
    parent_full_name: "",
    parent_email: "",
    parent_phone_number: "",
    date_created: "",
  },
  setStudent: (student: any) => {},
  assignStudentPoints: (
    points: number,
    description: string,
    category: string
  ) => {},
};

const StudentContext = createContext<StudentContext>(
  StudentContextDefaultValues
);

const StudentProvider: FC<StudentContextProps> = ({ children }) => {
  const [token, setToken] = useState("");

  const [student, setStudent] = useState({
    student_id: 0,
    f_name: "",
    l_name: "",
    id_number: "",
    grade: 0,
    subscription: "",
    subscription_token: "",
    subscription_amount: 0,
    authentication_token: "",
    authentication_otp: "",
    parent_full_name: "",
    parent_email: "",
    parent_phone_number: "",
    date_created: "",
  });

  const assignStudentPoints = (
    points: number,
    description: string,
    category: string
  ) => {
    if (student.subscription === "enabled") {
      assignPoints({
        token: token,
        description: description,
        points: points,
        points_category: category,
      })
        .then(() => {
          toast.success("Hoera! Jy het " + points + " punte verdien.");
        })
        .catch(console.log);
    }
  };

  return (
    <StudentContext.Provider
      value={{ token, setToken, student, setStudent, assignStudentPoints }}
    >
      {children}
    </StudentContext.Provider>
  );
};

export default StudentContext;

export { StudentProvider };
