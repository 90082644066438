import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { fetchStudent } from "../api/studentEndpoints";

import StudentContext from "../context/StudentContext";

import { LoginIcon } from "../components/Icons";

interface StudentCardProps {
  student: {
    student_id: number;
    student_f_name: string;
    student_l_name: string;
    student_grade: number;
    student_token: string;
  };
}

const StudentCard: FC<StudentCardProps> = ({ student }) => {
  const navigate = useNavigate();

  const { setToken, setStudent } = useContext(StudentContext);

  const handleStudentClick = () => {
    fetchStudent({ token: student.student_token })
      .then((response) => {
        if (response.data.status === "success") {
          toast.dismiss();

          setToken(student.student_token);
          setStudent(response.data.student);

          navigate("/student-dashboard");
        } else {
          toast.error(response.data.errorMessage);

          let studentTokens =
            JSON.parse(localStorage.getItem("student-tokens") as string) || [];

          localStorage.setItem(
            "student-tokens",
            JSON.stringify(
              studentTokens.filter((studentToken: string) => {
                return studentToken !== student.student_token;
              })
            )
          );

          toast.error(
            student.student_f_name +
              " se rekening is uitgeteken weens 'n fout. Teken asseblief weer in om voort te gaan."
          );

          navigate("/");
        }
      })
      .catch(console.log);
  };

  return (
    <div onClick={() => handleStudentClick()}>
      <div className="relative cc-card-background w-[200px] h-[240px] flex flex-col justify-start items-center rounded-lg shadow-2xl cursor-pointer group">
        <span className="mt-5 mb-5 py-4 px-3 border-2 border-white bg-d-green font-extrabold text-2xl text-white text-center rounded-full shadow-lg uppercase">
          {student.student_f_name[0]}
          {student.student_l_name[0]}
        </span>

        <span className="mb-2 font-bold text-base">
          {student.student_f_name} {student.student_l_name}
        </span>
        <span className="font-normal text-sm text-gray-200">
          Graad {student.student_grade}
        </span>

        <span className="absolute bottom-5 right-5 group-hover:animate-bounce">
          <LoginIcon />
        </span>
      </div>
    </div>
  );
};

export default StudentCard;
