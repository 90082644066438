import { FC } from "react";

interface OnlineBookCardProps {
  book: {
    book_id: string;
    book_title: string;
    grade: string;
    module: string;
    subject_id: string;
    cover_img_url: string;
    page_count: string;
    subject: string;
    price: number;
  };
  inCart: boolean;
  addToCart: (book: any) => void;
}

const OnlineBookCard: FC<OnlineBookCardProps> = ({
  book,
  inCart,
  addToCart,
}) => {
  return (
    <div className="w-[240px] mb-10 mr-8">
      <h3 className="mb-2 font-semibold text-center text-xs dark:text-white">
        {book.book_title}
      </h3>

      <img
        src={book.cover_img_url}
        alt={book.book_title}
        width={200}
        height={320}
        className="mx-auto rounded-lg"
      />

      <div className="relative w-[240px] -mt-10 mx-auto py-5 px-4 flex justify-between items-center bg-white rounded-lg shadow-lg dark:bg-l-gray">
        {inCart ? (
          <span className="text-black dark:text-white">In Mandjie!</span>
        ) : (
          <span
            className="text-d-green cursor-pointer hover:underline dark:text-l-green"
            onClick={() => addToCart(book)}
          >
            Voeg in Mandjie
          </span>
        )}

        <span className="text-black dark:text-white">
          R{book.price.toFixed(2)}
        </span>
      </div>
    </div>
  );
};

export default OnlineBookCard;
