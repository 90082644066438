import { FC, useContext } from "react";

import ThemeContext from "../context/ThemeContext";

interface SubscriptionBannerProps {}

const SubscriptionBanner: FC<SubscriptionBannerProps> = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className={`${
        theme === "light"
          ? "cc-subscription-banner-background"
          : "cc-subscription-banner-background-dark"
      } w-full flex justify-between items-center py-8 px-10 bg-white rounded-lg shadow-xl`}
    >
      <div className="flex flex-col justify-start items-start">
        <span className="font-semibold text-base text-black dark:text-white">
          Kry toegang to baie meer...
        </span>
        <h2 className="mt-3 mb-5 font-semibold text-4xl text-d-green dark:text-l-green">
          Nukleus Premie Leerder Rekening
        </h2>
        <p className="max-w-[620px] mb-16 text-base text-gray-100 dark:text-white">
          Nukleus Onderwys het soveel meer om te bied. Om jou rekening aan die
          Nukleus Aanlyn-lidmaatskap te koppel en toegang tot aanlyn klasse,
          opnames, oefeninge en die spesiale punte ranglys te kry kontak ons by{" "}
          <a
            href="mailto:aanlyn@nukleusonderwys.co.za"
            className="text-d-green dark:text-l-green cursor-pointer hover:underline"
          >
            aanlyn@nukleusonderwys.co.za
          </a>
          .
        </p>
        <span className="text-gray-200 dark:text-white">
          Aanlyn betalings is tans gesluit.
        </span>
      </div>

      <div className="flex justify-center items-center">
        <img
          src="http://nukleusontwerp.co.za/assets/nukleus-online-logo.png"
          alt="Nukleus Onderwys Logo"
          width={135}
          height={140}
        />

        <img
          src="http://nukleusontwerp.co.za/assets/nukleus-fitness-logo.png"
          alt="Nukleus Onderwys Logo"
          width={135}
          height={140}
        />

        <img
          src="http://nukleusontwerp.co.za/assets/nukleus-bookstore-logo.png"
          alt="Nukleus Onderwys Logo"
          width={135}
          height={140}
        />
      </div>
    </div>
  );
};

export default SubscriptionBanner;
