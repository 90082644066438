import { FC } from "react";

import { ClearIcon } from "../components/Icons";

interface ReaderTabChaptersProps {
  chapters: string;
  setPage: (page: number) => void;
  setShowTab: (show: boolean) => void;
}

const ReaderTabChapters: FC<ReaderTabChaptersProps> = ({
  chapters,
  setPage,
  setShowTab,
}) => {
  const chapterList = chapters.split(";");

  return (
    <div className="absolute w-[350px] h-full pt-24 pb-20 bottom-20 left-16 bg-white rounded-bl-3xl dark:bg-d-gray">
      <div className="flex justify-between items-center ml-2 mb-8 pt-4 pb-8 pl-8 pr-4 border-b border-gray-500">
        <h2 className="font-normal text-2xl text-black dark:text-white">
          Hoostukke
        </h2>

        <span className="mr-2 cursor-pointer hover:animate-pulse">
          <ClearIcon onClick={() => setShowTab(false)} />
        </span>
      </div>

      <div className="h-full pb-20 overflow-scroll">
        {chapterList.map((chapter) => {
          const chapterData = chapter.split("#");

          return (
            <div
              className="mt-4 ml-8 py-5 px-4 flex justify-between items-center bg-gray-500 cursor-pointer hover:bg-gray-400 group dark:bg-l-gray dark:text-white"
              onClick={() => setPage(parseInt(chapterData[1]))}
            >
              <span className="group-hover:underline">{chapterData[0]}</span>
              <span className="font-light text-sm">bl. {chapterData[1]}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReaderTabChapters;
