import { FC } from "react";

interface FormInputProps {
  label: string;
  inputType: string;
  inputName: string;
  formRegister?: any;
}

const FormInput: FC<FormInputProps> = ({
  label,
  inputType,
  inputName,
  formRegister,
}) => {
  return (
    <div className="w-full mb-12 flex flex-col justify-start items-start">
      <label className="mb-2 font-medium text-xs text-h-black uppercase dark:text-white">
        {label}
      </label>
      <input
        type={inputType}
        name={inputName}
        className="w-full max-w-[400px] h-[50px] pl-4 bg-white border border-gray-300 rounded-lg shadow-lg dark:bg-d-black dark:text-white dark:border-black"
        {...formRegister(inputName, { required: true })}
      />
    </div>
  );
};

export default FormInput;
