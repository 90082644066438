import { FC, useContext } from "react";
import { useMatch } from "react-router-dom";

import StudentContext from "../context/StudentContext";

import NavigationBarItem from "./NavigationBarItem";
import ThemeToggle from "./ThemeToggle";
import { CloseIcon, MenuIcon } from "./Icons";
import ThemeContext from "../context/ThemeContext";
import NavigationBarDropdown from "./NavigationBarDropdown";

interface NavigationBarProps {
  showMenu: boolean;
  toggleMenu: () => void;
}

const NavigationBar: FC<NavigationBarProps> = ({ showMenu, toggleMenu }) => {
  const { theme } = useContext(ThemeContext);
  const { student } = useContext(StudentContext);

  let navigationItems: { label: string; route: string }[] = [];

  if (useMatch("/no/*")) {
    navigationItems = [
      { label: "Boeke", route: "/no/library" },
      { label: "Opnames", route: "/no/gallery" },
      { label: "Klasse", route: "/no/classes" },
      { label: "Assesserings", route: "/no/studies" },
    ];
  }

  if (useMatch("/nf/*")) {
    navigationItems = [
      { label: "Oefeninge", route: "/nf/gallery" },
      { label: "Uitdagings", route: "/nf/rewards" },
    ];
  }

  if (useMatch("/nb/*")) {
    navigationItems = [
      { label: "Aanlyn Boeke", route: "/nb/online" },
      { label: "Fisiese Boeke", route: "/nb/orders" },
    ];
  }

  return (
    <div className="w-full h-full flex justify-between items-center flex-row-reverse lg:flex-row">
      <div className="ml-3.5">
        <span className="mr-4 py-3 px-2 border-2 border-white bg-d-green font-extrabold text-xl text-white text-center rounded-full shadow-lg uppercase dark:border-d-gray dark:bg-l-green">
          {student.f_name[0]}
          {student.l_name[0]}
        </span>
        <span className="hidden lg:inline mb-2 font-bold text-base dark:text-white">
          {student.f_name} {student.l_name}
        </span>
      </div>

      <div className="hidden lg:flex justify-center items-center space-x-4">
        {navigationItems.map((item) => {
          return (
            <NavigationBarItem
              key={item.label}
              label={item.label}
              route={item.route}
            />
          );
        })}
      </div>

      {navigationItems.length > 0 ? (
        <NavigationBarDropdown options={navigationItems} />
      ) : null}

      <div className="hidden lg:flex mr-10 justify-center items-center space-x-10">
        <span className="cursor-pointer group">
          <ThemeToggle />
        </span>
      </div>

      <div className="lg:hidden ml-6 mr-8 flex justify-center items-center">
        <span className="cursor-pointer group" onClick={toggleMenu}>
          {showMenu ? (
            theme === "light" ? (
              <CloseIcon className="fill-i-black group-hover:animate-pulse" />
            ) : (
              <CloseIcon className="fill-white group-hover:animate-pulse" />
            )
          ) : theme === "light" ? (
            <MenuIcon className="fill-i-black group-hover:animate-pulse" />
          ) : (
            <MenuIcon className="fill-white group-hover:animate-pulse" />
          )}
        </span>
      </div>
    </div>
  );
};

export default NavigationBar;
