import { FC } from "react";

interface FilterModuleProps {
  setFilterModule: (module: string) => void;
}

const FilterModule: FC<FilterModuleProps> = ({ setFilterModule }) => {
  return (
    <select
      className="cc-dropdown-arrow w-full max-w-[400px] lg:max-w-[200px] h-[50px] lg:ml-4 pl-4 text-base bg-white cursor-pointer rounded-lg shadow-xl dark:bg-d-black dark:text-white"
      onChange={(e) => setFilterModule(e.target.value)}
    >
      <option value="1">Module 1</option>
      <option value="2">Module 2</option>
      <option value="3">Module 3</option>
      <option value="4">Module 4</option>
    </select>
  );
};

export default FilterModule;
