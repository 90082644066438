import axios from "./axios";

interface checkPaymentRequest {
  token: string;
}

interface checkPaymentResponse {
  data: {
    status: "success" | "error";
    errorMessage?: string;
  };
}

export async function checkPayment(
  data: checkPaymentRequest
): Promise<checkPaymentResponse> {
  return axios.post("payments/check-payment.php", data);
}
