import { FC, ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import StudentContext from "../context/StudentContext";

import NavigationBar from "../components/NavigationBar";
import NavigationSidebar from "../components/NavigationSidebar";
import SubscriptionBanner from "./SubscriptionBanner";
import Spinner from "./Spinner";

interface MainLayoutProps {
  children: ReactNode | ReactNode[];
  enabledOnly?: boolean;
}

const MainLayout: FC<MainLayoutProps> = ({ children, enabledOnly }) => {
  let navigate = useNavigate();

  const { student } = useContext(StudentContext);
  if (!student.student_id) {
    navigate("/dashboard");
  }

  const [loading, setLoading] = useState(true);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoading(false);
    }, 1500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-screen h-screen overflow-hidden dark:bg-l-gray">
      <div className="relative w-full h-20 bg-white dark:bg-l-gray">
        <NavigationBar
          showMenu={showMenu}
          toggleMenu={() => setShowMenu(!showMenu)}
        />
      </div>

      <div className="h-full -mt-20 flex justify-start items-stretch">
        <div className="hidden lg:block w-20 h-full pt-20 bg-white dark:bg-l-gray">
          <NavigationSidebar />
        </div>

        {showMenu ? (
          <div className="block lg:hidden w-20 h-full pt-20 bg-white dark:bg-l-gray">
            <NavigationSidebar />
          </div>
        ) : null}

        <div className="w-full h-full mt-20 pt-12 pb-28 px-5 md:px-12 bg-gray-500 overflow-scroll lg:rounded-tl-3xl dark:bg-d-gray">
          {loading ? (
            <div className="w-full h-full flex justify-center items-center">
              <Spinner />
            </div>
          ) : enabledOnly ? (
            student.subscription === "enabled" ? (
              children
            ) : (
              <SubscriptionBanner />
            )
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
