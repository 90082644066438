import { FC } from "react";
import { useNavigate } from "react-router-dom";

import NavigationSidebarItem from "./NavigationSidebarItem";
import { LogoutIcon } from "./Icons";
import ThemeToggle from "./ThemeToggle";

interface NavigationSidebarProps {}

const NavigationSidebar: FC<NavigationSidebarProps> = () => {
  const navigate = useNavigate();

  let navigationItems = [
    {
      route: "/student-dashboard",
      match: "/student-dashboard",
      navigationIcon: "dashboard",
    },
    {
      route: "/no/library",
      match: "/no/*",
      navigationIcon: "no",
    },
    {
      route: "/nf/gallery",
      match: "/nf/*",
      navigationIcon: "nf",
    },
    {
      route: "/nb/online",
      match: "/nb/*",
      navigationIcon: "nb",
    },
    {
      route: "/ranking",
      match: "/ranking",
      navigationIcon: "ranking",
    },
    {
      route: "/profile",
      match: "/profile",
      navigationIcon: "profile",
    },
  ];

  return (
    <div className="w-full h-full flex flex-col justify-between items-center overflow-y-scroll">
      <div className="w-8 h-8"></div>

      <div className="w-full flex flex-col justify-center items-center space-y-10">
        {navigationItems.map((item) => {
          return (
            <NavigationSidebarItem
              key={item.navigationIcon}
              route={item.route}
              match={item.match}
              navigationIcon={item.navigationIcon}
            />
          );
        })}
      </div>

      <div className="mt-8 mb-8">
        <span className="mb-8 block lg:hidden cursor-pointer group">
          <ThemeToggle />
        </span>

        <span
          className="cursor-pointer group"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          <LogoutIcon className="fill-i-black group-hover:animate-pulse dark:fill-white" />
        </span>
      </div>
    </div>
  );
};

export default NavigationSidebar;
