import { FC } from "react";

import {
  BookTabChaptersIcon,
  BookTabBookmarkIcon,
  BookTabSettingsIcon,
} from "./Icons";

interface ReaderSidebarProps {
  showTabChapters: boolean;
  showTabBookmark: boolean;
  showTabSettings: boolean;
  setShowTabChapters: (show: boolean) => void;
  setShowTabBookmark: (show: boolean) => void;
  setShowTabSettings: (show: boolean) => void;
}

const ReaderSidebar: FC<ReaderSidebarProps> = ({
  showTabChapters,
  showTabBookmark,
  showTabSettings,
  setShowTabChapters,
  setShowTabBookmark,
  setShowTabSettings,
}) => {
  return (
    <div className="mt-24 flex flex-col justify-center items-center space-y-8">
      {/* <span className="cursor-pointer hover:animate-pulse">
        <BookTabChaptersIcon
          className={
            showTabChapters
              ? "fill-d-green dark:fill-l-green"
              : "fill-black dark:fill-white"
          }
          onClick={() => {
            setShowTabChapters(!showTabChapters);
            setShowTabBookmark(false);
            setShowTabSettings(false);
          }}
        />
      </span> */}

      <span className="cursor-pointer hover:animate-pulse">
        <BookTabBookmarkIcon
          className={
            showTabBookmark
              ? "fill-d-green dark:fill-l-green"
              : "fill-black dark:fill-white"
          }
          onClick={() => {
            setShowTabChapters(false);
            setShowTabBookmark(!showTabBookmark);
            setShowTabSettings(false);
          }}
        />
      </span>

      <span className="cursor-pointer hover:animate-pulse">
        <BookTabSettingsIcon
          className={
            showTabSettings
              ? "fill-d-green dark:fill-l-green"
              : "fill-black dark:fill-white"
          }
          onClick={() => {
            setShowTabChapters(false);
            setShowTabBookmark(false);
            setShowTabSettings(!showTabSettings);
          }}
        />
      </span>
    </div>
  );
};

export default ReaderSidebar;
