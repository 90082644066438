import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OTPInput from "otp-input-react";
import { toast } from "react-toastify";

import { requestOTP, verifyOTP } from "../api/authenticationEndpoints";

import AuthenticationLayout from "../components/AuthenticationLayout";
import FormHeading from "../components/FormHeading";
import Button from "../components/Button";

const AuthenticateOTPPage: FC = () => {
  const studentID = localStorage.getItem("authentication-student-id") as string;
  const navigate = useNavigate();

  const [resendTimer, setResendTimer] = useState(0);
  const [authenticationOTP, setAuthenticationOTP] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      if (resendTimer > 0) {
        setResendTimer(resendTimer - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [resendTimer]);

  const handleRequestOTP = () => {
    setResendTimer(30);

    requestOTP({ student_id_number: studentID })
      .then((response) => {
        if (response.data.status === "error") {
          setResendTimer(0);
          toast.error(response.data.errorMessage);
        }
      })
      .catch(console.log);
  };

  const handleAuthentication = () => {
    if (authenticationOTP.length === 6) {
      verifyOTP({
        student_id_number: studentID,
        authentication_otp: authenticationOTP,
      })
        .then((response) => {
          if (response.data.status === "success") {
            let studentTokens =
              JSON.parse(localStorage.getItem("student-tokens") as string) ||
              [];

            if (!studentTokens.includes(response.data.token)) {
              studentTokens.push(response.data.token);
              localStorage.setItem(
                "student-tokens",
                JSON.stringify(studentTokens)
              );

              navigate("/dashboard");
            } else {
              toast.error("Leerder is reeds ingeteken.");
            }
          } else {
            toast.error(response.data.errorMessage);
          }
        })
        .catch(console.log);
    } else {
      toast.error("Verskaf asseblief die unieke kode.");
    }
  };

  return (
    <AuthenticationLayout withPrev>
      <div className="px-5 max-w-[400px]">
        <FormHeading headingText="Twee-stap Verifikasie" />

        <p className="text-center lg:text-left mb-14 text-base text-gray-300 dark:text-white">
          Unieke Kode gestuur na: <br />
          <span className="font-semibold text-xl">
            {localStorage.getItem("authentication-student-email")}
          </span>
        </p>

        <OTPInput
          value={authenticationOTP}
          onChange={setAuthenticationOTP}
          OTPLength={6}
          otpType="number"
          autoFocus
          className="w-full flex justify-between items-center mb-5"
          inputClassName="m-0 p-0 bg-white border border-gray-300 rounded-lg shadow-lg dark:bg-d-black"
          inputStyles={{
            width: "50px",
            height: "50px",
            marginRight: "0px",
          }}
        />

        <p className="text-center lg:text-left text-xs text-gray-200 dark:text-white">
          Gaan jou e-pos na vir 'n private sekuriteit e-pos van Nukleus, dan
          kopieer die unieke kode hier om jou e-posadres te bevestig.
        </p>

        <div className="mt-14 flex flex-col-reverse md:flex-row justify-between items-center">
          {resendTimer > 0 ? (
            <span className="text-xs text-gray-200 mt-10 md:mt-0">
              {resendTimer} sekondes...
            </span>
          ) : (
            <span
              className="font-medium text-sm text-d-green cursor-pointer hover:underline dark:text-l-green mt-10 md:mt-0"
              onClick={() => handleRequestOTP()}
            >
              stuur e-pos weer
            </span>
          )}

          <Button
            type="outlined"
            buttonText="Teken In"
            onClick={() => {
              handleAuthentication();
            }}
            withIcon
          />
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default AuthenticateOTPPage;
