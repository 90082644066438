import { FC } from "react";

interface MainHeadingProps {
  plainText: string;
  themeText: string;
}

const MainHeading: FC<MainHeadingProps> = ({ plainText, themeText }) => {
  return (
    <h1 className="text-center font-bold text-4xl text-h-black dark:text-white">
      {plainText}{" "}
      <span className="text-transparent bg-clip-text bg-gradient-to-b from-l-green to-d-green">
        {themeText}
      </span>
    </h1>
  );
};

export default MainHeading;
