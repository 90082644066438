import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { ClassIcon, AssessmentIcon } from "../components/Icons";

interface StudentScheduleCardProps {
  type: "class" | "assessment";
  title: string;
  oTime: string;
  cTime: string;
  isReady: boolean;
  isComplete: boolean;
  classLink?: string;
  assessment?: {};
}

const StudentScheduleCard: FC<StudentScheduleCardProps> = ({
  type,
  title,
  oTime,
  cTime,
  isReady,
  isComplete,
  classLink,
  assessment,
}) => {
  const navigate = useNavigate();

  const getTime = (timeString: string) => {
    const currentTime = new Date(timeString);

    return (
      (currentTime.getHours() < 10
        ? "0" + currentTime.getHours()
        : currentTime.getHours()) +
      ":" +
      (currentTime.getMinutes() < 10
        ? "0" + currentTime.getMinutes()
        : currentTime.getMinutes())
    );
  };

  return (
    <div className="mb-5 flex flex-col md:flex-row justify-between md:items-center">
      <span className="mr-10 font-medium text-sm text-black dark:text-white">
        {getTime(oTime)} - {getTime(cTime)}
      </span>

      <div className="flex-grow px-4 lg:px-8 py-4 flex flex-col md:flex-row justify-between items-center bg-gray-500 rounded-xl dark:bg-d-gray dark:text-white">
        <div className="flex justify-center items-center">
          {type === "class" ? (
            <ClassIcon className="hidden lg:inline fill-black dark:fill-white" />
          ) : (
            <AssessmentIcon className="hidden lg:inline fill-black dark:fill-white" />
          )}

          <span className="mb-5 md:mb-0 lg:ml-8 text-center">{title}</span>
        </div>

        {isReady ? (
          isComplete ? (
            <span>Klaar!</span>
          ) : type === "class" ? (
            <a
              className="text-d-green cursor-pointer hover:underline dark:text-l-green"
              href={classLink}
              target="_blank"
              rel="noreferrer"
            >
              Begin Klas
            </a>
          ) : (
            <span
              className="text-d-green cursor-pointer hover:underline dark:text-l-green"
              onClick={() => {
                localStorage.setItem(
                  "active-assessment",
                  JSON.stringify(assessment)
                );
                navigate("/no/testing");
              }}
            >
              Begin Assessering
            </span>
          )
        ) : null}
      </div>
    </div>
  );
};

export default StudentScheduleCard;
