import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import ThemeToggle from "./ThemeToggle";
import { PrevIcon } from "./Icons";

interface AuthenticationLayoutProps {
  children: ReactNode | ReactNode[];
  withPrev?: boolean;
}

const AuthenticationLayout: FC<AuthenticationLayoutProps> = ({
  children,
  withPrev,
}) => {
  const navigate = useNavigate();

  return (
    <div className="w-screen min-h-screen flex flex-col justify-center items-center dark:bg-l-gray dark:text-white pt-28 pb-10">
      {withPrev ? (
        <div
          className="absolute top-5 md:top-10 left-5 md:left-10 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <PrevIcon />
        </div>
      ) : null}

      <div className="absolute top-5 md:top-10 right-5 md:right-10">
        <ThemeToggle />
      </div>

      {children}
    </div>
  );
};

export default AuthenticationLayout;
