/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from "react";

import { BookPrevIcon, BookNextIcon } from "../components/Icons";

interface ReaderControlsPageProps {
  page: number;
  pageCount: number;
  setPage: (page: number) => void;
}

const ReaderControlsPage: FC<ReaderControlsPageProps> = ({
  page,
  pageCount,
  setPage,
}) => {
  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < pageCount) {
      setPage(page + 1);
    }
  };

  const handleFPage = () => {
    setPage(1);
  };

  const handleLPage = () => {
    setPage(pageCount - 1);
  };

  useEffect(() => {
    const handleShortcuts = (e: { keyCode: any }) => {
      if (e.keyCode === 37) {
        handlePrevPage();
      }

      if (e.keyCode === 39) {
        handleNextPage();
      }

      if (e.keyCode === 69) {
        handleFPage();
      }

      if (e.keyCode === 76) {
        handleLPage();
      }
    };

    document.addEventListener("keydown", handleShortcuts);

    return () => {
      document.removeEventListener("keydown", handleShortcuts);
    };
  }, [handleNextPage, handlePrevPage]);

  return (
    <div className="-mr-56 flex justify-center items-center">
      <span
        className="mr-2 cursor-pointer hover:animate-pulse"
        onClick={handlePrevPage}
      >
        <BookPrevIcon className="fill-d-green dark:fill-l-green" />
      </span>
      <span
        className="ml-2 cursor-pointer hover:animate-pulse"
        onClick={handleNextPage}
      >
        <BookNextIcon className="fill-d-green dark:fill-l-green" />
      </span>
    </div>
  );
};

export default ReaderControlsPage;
