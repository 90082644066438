import axios from "./axios";

interface FetchRankListRequest {
  token: string;
}

interface FetchRankListResponse {
  data: {
    status: "success" | "error";
    rankings?: {
      student_id: string;
      points: string;
      current_rank: string;
      student: {
        f_name: string;
        l_name: string;
      };
    }[];
    errorMessage?: string;
  };
}

export async function fetchRankList(
  data: FetchRankListRequest
): Promise<FetchRankListResponse> {
  return axios.post("ranking/fetch-rankings-list.php", data);
}

interface FetchRankPointsRequest {
  token: string;
}

interface FetchRankPointsResponse {
  data: {
    status: "success" | "error";
    points?: {
      log_description: string;
      points: number;
      date_created: string;
    }[];
    errorMessage?: string;
  };
}

export async function fetchRankPoints(
  data: FetchRankPointsRequest
): Promise<FetchRankPointsResponse> {
  return axios.post("ranking/fetch-rankings-points.php", data);
}

interface AssignPointsRequest {
  token: string;
  description: string;
  points: number;
  points_category: string;
}

interface AssignPointsResponse {
  data: {
    status: "success" | "error";
    errorMessage?: string;
  };
}

export async function assignPoints(
  data: AssignPointsRequest
): Promise<AssignPointsResponse> {
  return axios.post("ranking/assign-points.php", data);
}
