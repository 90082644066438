import { FC } from "react";
import { useNavigate, useMatch } from "react-router-dom";

interface NavigationBarItemProps {
  label: string;
  route: string;
}

const NavigationBarItem: FC<NavigationBarItemProps> = ({ label, route }) => {
  const navigate = useNavigate();
  const isActive = useMatch(route);

  return (
    <span
      className={`${
        isActive
          ? "text-white bg-gradient-to-b from-l-green to-d-green"
          : "text-gray-300 bg-gray-500  cursor-pointer hover:shadow-lg dark:bg-[#323232] dark:text-white"
      } py-2 px-8 font-semibold text-base rounded-3xl`}
      onClick={() => {
        navigate(route);
      }}
    >
      {label}
    </span>
  );
};

export default NavigationBarItem;
