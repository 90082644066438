import axios from "./axios";

interface FetchAssessmentsRequest {
  token: string;
}

interface FetchAssessmentsResponse {
  data: {
    status: "success" | "error";
    assessments?: {
      assessment_id: number;
      assessment_title: string;
      grade: number;
      module: number;
      subject_id: number;
      assessment_url: string;
      o_datetime: string;
      c_datetime: string;
      date_created: string;
      subject: string;
      completed: string;
      year: number;
    }[];
    errorMessage?: string;
  };
}

export async function fetchAssessments(
  data: FetchAssessmentsRequest
): Promise<FetchAssessmentsResponse> {
  return axios.post("no/assessments/fetch-assessments.php", data);
}

interface UploadAssessmentsResponse {
  data: {
    status: "success" | "error";
    assessmentFileName: string;
    assessmentFileURL: string;
    errorMessage?: string;
  };
}

export async function uploadAssessments(
  data: FormData
): Promise<UploadAssessmentsResponse> {
  return axios.post("no/assessments/upload-assessment.php", data, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

interface CompleteAssessmentRequest {
  token: string;
  assessment_id: number;
  assessment_file_name: string;
  assessment_file_url: string;
}

interface CompleteAssessmentResponse {
  data: {
    status: "success" | "error";
    errorMessage?: string;
  };
}

export async function completeAssessment(
  data: CompleteAssessmentRequest
): Promise<CompleteAssessmentResponse> {
  return axios.post("no/assessments/complete-assessment.php", data);
}

interface FetchAssessmentsCompletedRequest {
  token: string;
}

interface FetchAssessmentsCompletedResponse {
  data: {
    status: "success" | "error";
    assessmentsCompleted?: {
      assessment_completed_id: number;
      assessment_id: number;
      student_id: number;
      assessment_completed_url: string;
      assessment_completed_filename: string;
      assessment_marked_url: string;
      marked_by: number;
      resubmit_flag: string;
      date_created: string;
      year: number;
      assessment: {
        assessment_id: number;
        assessment_title: string;
        grade: number;
        module: number;
        subject_id: number;
        assessment_url: string;
        o_datetime: string;
        c_datetime: string;
        date_created: string;
        subject: string;
        completed: string;
        year: number;
      };
    }[];
    errorMessage?: string;
  };
}

export async function fetchAssessmentsCompleted(
  data: FetchAssessmentsCompletedRequest
): Promise<FetchAssessmentsCompletedResponse> {
  return axios.post("no/assessments/fetch-assessments-completed.php", data);
}
