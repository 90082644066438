import { FC, useEffect, useState } from "react";

interface DateTimeProps {}

const DateTime: FC<DateTimeProps> = () => {
  const currentDate = new Date();

  const listOfDays = [
    "Sondag",
    "Maandag",
    "Dinsdag",
    "Woensdag",
    "Donderdag",
    "Vrydag",
    "Saterdag",
  ];

  const listOfMonths = [
    "Januarie",
    "Februarie",
    "Maart",
    "April",
    "Mei",
    "Junie",
    "Julie",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const [time, setTime] = useState("");

  useEffect(() => {
    let currentTime = new Date();

    setTime(
      (currentTime.getHours() < 10
        ? "0" + currentTime.getHours()
        : currentTime.getHours()) +
        ":" +
        (currentTime.getMinutes() < 10
          ? "0" + currentTime.getMinutes()
          : currentTime.getMinutes())
    );

    const interval = setInterval(() => {
      let currentTime = new Date();

      setTime(
        (currentTime.getHours() < 10
          ? "0" + currentTime.getHours()
          : currentTime.getHours()) +
          ":" +
          (currentTime.getMinutes() < 10
            ? "0" + currentTime.getMinutes()
            : currentTime.getMinutes())
      );
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <span className="text-gray-600">
      {listOfDays[currentDate.getDay()]}, {currentDate.getDate()}{" "}
      {listOfMonths[currentDate.getMonth()]} {currentDate.getFullYear()} -{" "}
      {time}
    </span>
  );
};

export default DateTime;
