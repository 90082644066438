import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import MainHeading from "../components/MainHeading";
import StudentCard from "../components/StudentCard";
import Button from "../components/Button";

interface StudentProfile {
  student_id: number;
  student_f_name: string;
  student_l_name: string;
  student_grade: number;
  student_token: string;
}

const DashboardPage: FC = () => {
  const navigate = useNavigate();

  const [students, setStudents] = useState<StudentProfile[]>([]);

  useEffect(() => {
    const studentTokens = JSON.parse(
      localStorage.getItem("student-tokens") as string
    );

    if (studentTokens) {
      let studentProfiles: StudentProfile[] = [];

      studentTokens.forEach((token: string) => {
        studentProfiles.push({ ...jwt_decode(token), student_token: token });
      });

      setStudents(studentProfiles);
    }
  }, []);

  return (
    <div className="w-full min-h-screen flex flex-col justify-center items-center py-10 bg-white dark:bg-l-gray">
      <img
        src="http://nukleusontwerp.co.za/assets/nukleus-icon.png"
        alt="Nukleus Onderwys Logo"
        width={100}
        height={112}
      />

      <div className="mt-12 mb-4">
        <MainHeading plainText="Kies ‘n" themeText="Leerder" />
      </div>

      <p className="mb-11 text-center text-base text-gray-100 dark:text-white">
        Welkom terug, kies 'n profiel en begin leer.
      </p>

      <div className="max-w-[80%] flex flex-wrap justify-center items-center gap-8">
        {students.map((student) => {
          return <StudentCard key={student.student_id} student={student} />;
        })}
      </div>

      <div className="mt-20 flex flex-col md:flex-row justify-center items-center md:space-x-8 space-y-5 md:space-y-0">
        <Button
          type="solid"
          buttonText="Registreer ‘n Nuwe Leerder"
          onClick={() => navigate("/register-student")}
        />

        <Button
          type="outlined"
          buttonText="Teken In met Bestaande Leerder"
          onClick={() => navigate("/authenticate-sid")}
        />
      </div>
    </div>
  );
};

export default DashboardPage;
